<template>

  <div>

    <tinymce_editor ref="editor"
    >
    </tinymce_editor>

    <el-row>
      <el-button type="primary" @click="saveInsert">确 定</el-button>
    </el-row>

  </div>
</template>

<script>
import TinyMce from '../../components/Tinymce/Tinymce.vue';

export default {
  name: "Txt",
  inject: ['reload'],
  components: {
    tinymce_editor: TinyMce,
  },
  data() {
    return {

      aboutUs: {
        usId: '',
        usDesc: ''
      },

      idx: -1,
      id: -1
    }
  },
  created() {
    this.getData()
  },
  methods: {


    // 获取数据
    getData() {
      this.$axios({
        method: "get",
        url: "/aboutUs/get",
      }).then(response => {
        if(response.data.code === 200) {
          this.aboutUs = response.data.datas.aboutUs
          this.$refs.editor.myValue = response.data.datas.aboutUs.usDesc
        }
      })
    },


    // 添加
    saveInsert() {
      this.aboutUs.usDesc = this.$refs.editor.myValue
      this.$axios({
        method: "post",
        url: "/aboutUs/save",
        data: this.aboutUs
      }).then(response => {
        if (response.data.code == 200) {
          this.$message.success("添加成功");
          this.reload();
        } else {
          this.$message.error("添加失败");
        }
      })
    },

  }
}
</script>

<style scoped>
.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 300px;
  display: inline-block;
}

.table {
  width: 100%;
  font-size: 14px;
}

.red {
  color: #ff0000;
}

.mr10 {
  margin-right: 10px;
}

.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
