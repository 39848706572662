<template>
  <div>
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <i class="el-icon-lx-cascades"></i> 基础表格
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <div v-if="page === true">
      <div class="container">
        <div class="handle-box">
          <el-input v-model="parents.parentsName" placeholder="用户昵称" class="handle-input mr10"></el-input>
          <el-input v-model="parents.parentsPhone" placeholder="手机号" class="handle-input mr10"></el-input>
          <el-button type="primary" icon="el-icon-search" @click="handleSearch">搜索</el-button>
        </div>
        <el-table
            :data="tableData"
            border
            class="table"
            ref="multipleTable"
            header-cell-class-name="table-header"
            @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55" align="center"></el-table-column>
          <el-table-column
              type="index"
              width="50"
              label="ID"
              align="center">
          </el-table-column>
          <el-table-column prop="parentsName" label="用户昵称"></el-table-column>
          <el-table-column prop="parentsPhone" label="手机号"></el-table-column>
          <el-table-column prop="parentsTime" label="注册时间"></el-table-column>
          <el-table-column prop="credits.creditsTotal" label="积分总额"></el-table-column>
          <el-table-column label="操作" width="180" align="center">
            <template #default="scope">
              <el-button
                  type="text"
                  icon="el-icon-edit"
                  @click="handleEdit(scope.$index, scope.row)"
              >编辑
              </el-button>
              <el-button
                  type="text"
                  icon="el-icon-coin"
                  @click="showClick(scope.$index, scope.row)"
              >查看消费明细
              </el-button>
            </template>
          </el-table-column>
        </el-table>

        <el-pagination background
                       @current-change="handleCurrentChange"
                       :current-page="pageNum"
                       :page-size="pageSize" layout="total, prev, pager, next, jumper"
                       :total="totalCount">
        </el-pagination>
      </div>

      <!-- 编辑弹出框 -->
      <el-dialog title="编辑" v-model="editVisible" width="30%">
        <el-form ref="editForm" :model="credits" label-width="70px">
          <el-form-item label="积分修改">
            <el-input v-model="credits.creditsTotal"></el-input>
          </el-form-item>
        </el-form>
        <template #footer>
                <span class="dialog-footer">
                    <el-button @click="editVisible = false">取 消</el-button>
                    <el-button type="primary" @click="saveEdit">确 定</el-button>
                </span>
        </template>
      </el-dialog>
    </div>


    <!-- 用户消费详情页面 -->
    <div class="container" v-if="page === false">
      <div class="handle-box">
        <el-button type="primary" icon="el-icon-back" @click="back">后 退</el-button>
      </div>
      <el-table
          :data="creditsInfo"
          border
          class="table"
          ref="multipleTable"
          header-cell-class-name="table-header"
          @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" align="center"></el-table-column>
        <el-table-column
            type="index"
            width="50"
            label="ID"
            align="center">
        </el-table-column>
        <el-table-column prop="ciInfo" label="消费信息"></el-table-column>
        <el-table-column prop="ciNumber" label="积分数量">
          <template v-slot="scope">
            <p v-if="scope.row.ciType==='1'">
              <el-tag type="success">+{{ scope.row.ciNumber }}</el-tag>
            </p>
            <p v-if="scope.row.ciType==='0'">
              <el-tag type="danger">-{{ scope.row.ciNumber }}</el-tag>
            </p>
          </template>
        </el-table-column>
        <el-table-column prop="ciTime" label="消费时间"></el-table-column>
      </el-table>
      <el-pagination background
                     @current-change="handleCurrentChange"
                     :current-page="pageNum"
                     :page-size="pageSize" layout="total, prev, pager, next, jumper"
                     :total="totalCount">
      </el-pagination>
    </div>


  </div>
</template>

<script>
export default {
  name: "Credits",
  inject: ['reload'],
  data() {
    return {

      page: true,

      tableData: [],

      creditsInfo: [],

      parents: {
        parentsId: '',
        parentsName: '',
        parentsPhone: ''
      },

      credits: {
        creditsId: '',
        creditsTotal: ''
      },

      creditsShare: {
        csNumber: ''
      },

      editVisible: false,

      // 默认显示第几页
      pageNum: 1,
      // 总条数，根据接口获取数据长度(注意：这里不能为空)
      totalCount: 1,
      // 默认每页显示的条数（可修改）
      pageSize: 10,

      idx: -1,
      id: -1

    }
  },
  created() {
    this.getData(this.pageSize, this.pageNum)
  },
  methods: {
    // 多选操作
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },

    // 获取数据
    getData(pageSize, pageNum) {
      this.$axios({
        method: "get",
        url: "/credits/getList",
        params: {
          "parentsName": this.parents.parentsName,
          "parentsPhone": this.parents.parentsPhone,
          "pageSize": pageSize,
          "pageNum": pageNum
        }
      }).then(response => {
        console.log(response)
        this.tableData = response.data.datas.creditsPageInfo.list
        this.totalCount = response.data.datas.creditsPageInfo.total
      })
    },

    // 搜索
    handleSearch() {
      this.getData(this.pageSize, this.pageNum)
    },

    // 编辑操作
    handleEdit(index, row) {
      console.log(row)
      this.idx = index;
      this.credits.creditsId = row.credits.creditsId
      this.editVisible = true;
    },
    // 保存修改
    saveEdit() {
      this.$axios({
        method: "put",
        url: "/credits/edit",
        data: this.credits
      }).then(response => {
        console.log(response)
        if (response.data.code == 200) {
          this.$message.success(`修改第 ${this.idx + 1} 行成功`);
          this.reload();
        } else {
          this.$message.error("修改失败");
        }
      })
    },

    showClick(index, row) {
      this.page = false;
      this.parents.parentsId = row.parentsId
      this.showCreditsInfo(this.pageSize, this.pageNum)
    },

    showCreditsInfo(pageSize, pageNum) {
      this.$axios({
        method: "get",
        url: "/credits/getInfo",
        params: {
          "parentsId": this.parents.parentsId,
          "pageSize": pageSize,
          "pageNum": pageNum
        }
      }).then(response => {
        console.log(response)
        this.creditsInfo = response.data.datas.creditsPageInfo.list[0].creditsInfoList
        this.totalCount = response.data.datas.creditsPageInfo.total
      })
    },

    back() {
      this.page = true
      this.reload()
    },


    // 分页
    // 显示第几页
    handleCurrentChange(val) {
      // 切换页码时，要获取每页显示的条数
      if (this.page === true) {
        this.getData(this.pageSize, val)
      } else {
        this.showCreditsInfo(this.pageSize, val)
      }
    },

  }
}
</script>

<style scoped>
.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 300px;
  display: inline-block;
}

.table {
  width: 100%;
  font-size: 14px;
}

.red {
  color: #ff0000;
}

.mr10 {
  margin-right: 10px;
}

.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}
</style>
