<template>
  <div>
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <i class="el-icon-lx-cascades"></i> 基础表格
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="container">
      <div class="handle-box">
        <el-button
            type="success"
            icon="el-icon-plus"
            class="handle-del mr10"
            @click="insertVisible = true"
            style="margin-right: 50px"
        >添 加
        </el-button>
      </div>
      <el-table
          :data="tableData"
          border
          class="table"
          ref="multipleTable"
          header-cell-class-name="table-header"
          @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" align="center"></el-table-column>
        <el-table-column
            type="index"
            width="50"
            label="ID"
            align="center">
        </el-table-column>
        <el-table-column prop="adminName" label="管理员姓名"></el-table-column>
        <el-table-column prop="username" label="管理员账号"></el-table-column>
        <el-table-column prop="password" label="管理员密码"></el-table-column>
        <el-table-column prop="roleName" label="管理员角色"></el-table-column>
        <el-table-column prop="orgName" label="所属机构"></el-table-column>
        <el-table-column prop="adminStatus" label="是否禁用">
          <template v-slot="scope">
            <el-switch
                active-text="启用"
                inactive-text="禁用"
                active-color="#13ce66"
                inactive-color="#ff4949"
                active-value="1"
                inactive-value="0"
                v-model="scope.row.adminStatus"
                @click=change(scope.$index,scope.row)
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="180" align="center">
          <template #default="scope">
            <el-button
                type="text"
                icon="el-icon-edit"
                @click="handleEdit(scope.$index, scope.row)"
            >编辑
            </el-button>
            <el-button
                type="text"
                icon="el-icon-delete"
                class="red"
                @click="handleDelete(scope.$index, scope.row)"
            >删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination background
                     @current-change="handleCurrentChange"
                     :current-page="pageNum"
                     :page-size="pageSize" layout="total, prev, pager, next, jumper"
                     :total="totalCount">
      </el-pagination>
    </div>

    <!-- 添加弹出框 -->
    <el-dialog title="添加" v-model="insertVisible" width="30%" :before-close="close">
      <el-form ref="insertVisible" :model="admin" label-width="100px">
        <el-form-item label="管理员姓名">
          <el-input v-model="admin.adminName"></el-input>
        </el-form-item>
        <el-form-item label="管理员账号">
          <el-input v-model="admin.username"></el-input>
        </el-form-item>
        <el-form-item label="管理员密码">
          <el-input v-model="admin.password"></el-input>
        </el-form-item>
        <el-form-item label="管理员角色">
          <el-select v-model="admin.roleId" placeholder="请选择管理员角色" style="margin-right: 30px">
            <el-option
                v-for="item in roleData"
                :key="item.roleId"
                :label="item.roleDesc"
                :value="item.roleId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属机构">
          <el-select v-model="admin.orgId" placeholder="若为机构管理员，请选择所属机构" style="margin-right: 30px">
            <el-option
                v-for="item in orgData"
                :key="item.orgId"
                :label="item.orgName"
                :value="item.orgId">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
                <span class="dialog-footer">
                    <el-button @click="closeInsert">取 消</el-button>
                    <el-button type="primary" @click="saveInsert">确 定</el-button>
                </span>
      </template>
    </el-dialog>

    <!-- 编辑弹出框 -->
    <el-dialog title="编辑" v-model="editVisible" width="30%" :before-close="close">
      <el-form ref="editForm" :model="admin" label-width="100px">
        <el-form-item label="管理员姓名">
          <el-input v-model="admin.adminName"></el-input>
        </el-form-item>
        <el-form-item label="管理员账号">
          <el-input v-model="admin.username"></el-input>
        </el-form-item>
        <el-form-item label="管理员密码">
          <el-input v-model="admin.password"></el-input>
        </el-form-item>
        <el-form-item label="管理员角色">
          <el-select v-model="admin.roleId" placeholder="请选择管理员角色" style="margin-right: 30px">
            <el-option
                v-for="item in roleData"
                :key="item.roleId"
                :label="item.roleDesc"
                :value="item.roleId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属机构">
          <el-select v-model="admin.orgId" placeholder="若为机构管理员，请选择所属机构" style="margin-right: 30px">
            <el-option
                v-for="item in orgData"
                :key="item.orgId"
                :label="item.orgName"
                :value="item.orgId">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
                <span class="dialog-footer">
                    <el-button @click="closeEdit">取 消</el-button>
                    <el-button type="primary" @click="saveEdit">确 定</el-button>
                </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "Admin",
  inject: ['reload'],
  data() {
    return {

      tableData: [],

      roleData: [],

      admin: {
        adminId: '',
        username: '',
        password: '',
        adminName: '',
        adminStatus: '',
        roleId: '',
        orgId: '',
      },

      role: {
        roleId: '',
        roleName: '',
        roleDesc: ''
      },

      orgData: [],

      insertVisible: false,
      editVisible: false,

      // 默认显示第几页
      pageNum: 1,
      // 总条数，根据接口获取数据长度(注意：这里不能为空)
      totalCount: 1,
      // 默认每页显示的条数（可修改）
      pageSize: 10,

      idx: -1,
      id: -1
    }
  },
  created() {
    this.$axios({
      method: "get",
      url: "/org/getList",
      params: {"orgName": '', "pageSize": -1, "pageNum": 0}
    }).then(response => {
      this.orgData = response.data.datas.organizationList
      this.$axios({
        method: "get",
        url: "/role/getList",
        params: {"pageSize": -1, "pageNum": 0}
      }).then(response => {
        this.roleData = response.data.datas.roleList
        this.$axios({
          method: "get",
          url: "/admin/getList",
          params: {"pageSize": this.pageSize, "pageNum": this.pageNum}
        }).then(response => {
          this.tableData = response.data.datas.adminPageInfo.list
          this.totalCount = response.data.datas.adminPageInfo.total
          // roleId修改
          for (let i = 0; i < this.tableData.length; i++) {
            for (let j = 0; j < this.roleData.length; j++) {
              if (this.tableData[i].roleId == this.roleData[j].roleId) {
                this.tableData[i].roleName = this.roleData[j].roleDesc
              }
            }
          }

          // 修改表格中的orgId为orgName的值
          for (let i = 0; i < this.tableData.length; i++) {
            for (let j = 0; j < this.orgData.length; j++) {
              if (this.tableData[i].orgId === this.orgData[j].orgId) {
                this.tableData[i].orgName = this.orgData[j].orgName
              }
            }
          }
        })
      })
    })
  },

  // this.getOrg(-1, 0)
  // this.getRoleData(-1, this.pageNum)
  // this.getData(this.pageSize, this.pageNum)

  methods: {

    // 多选操作
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },

    // 获取数据
    getData(pageSize, pageNum) {
      this.$axios({
        method: "get",
        url: "/admin/getList",
        params: {"pageSize": pageSize, "pageNum": pageNum}
      }).then(response => {
        console.log(response)
        this.tableData = response.data.datas.adminPageInfo.list
        this.totalCount = response.data.datas.adminPageInfo.total
        // roleId修改
        for (let i = 0; i < this.tableData.length; i++) {
          for (let j = 0; j < this.roleData.length; j++) {
            if (this.tableData[i].roleId == this.roleData[j].roleId) {
              this.tableData[i].roleId = this.roleData[j].roleDesc
            }
          }
        }
      })
    },

    // 获取数据
    getRoleData(pageSize, pageNum) {
      this.$axios({
        method: "get",
        url: "/role/getList",
        params: {"pageSize": pageSize, "pageNum": pageNum}
      }).then(response => {
        console.log(response)
        this.roleData = response.data.datas.roleList
      })
    },

    // 获取所有的机构下拉选择
    getOrg(pageSize, pageNum) {
      this.$axios({
        method: "get",
        url: "/org/getList",
        params: {"orgName": '', "pageSize": pageSize, "pageNum": pageNum}
      }).then(response => {
        this.orgData = response.data.datas.organizationList
      })
    },

    // 添加
    saveInsert() {
      this.$axios({
        method: "post",
        url: "/admin/save",
        data: this.admin
      }).then(response => {
        console.log(response)
        if (response.data.code == 200) {
          this.$message.success(response.data.msg);
          this.reload();
        } else {
          this.$message.error(response.data.msg);
        }
      })
    },

    // 编辑操作
    handleEdit(index, row) {
      console.log(row)
      this.idx = index;
      this.admin = row;
      this.editVisible = true;
    },
    // 保存修改
    saveEdit() {
      this.$axios({
        method: "put",
        url: "/admin/edit",
        data: this.admin
      }).then(response => {
        console.log(response)
        if (response.data.code == 200) {
          this.$message.success(`修改第 ${this.idx + 1} 行成功`);
          this.reload();
        } else {
          this.$message.error(response.data.msg);
        }
      })
    },

    // 删除操作
    handleDelete(index, row) {
      console.log(row)
      if (row.roleId === "超级管理员") {
        this.$message.error("超级管理员无法删除");
        return;
      }
      // 二次确认删除
      this.$confirm("确定要删除吗？", "提示", {
        type: "warning",
      }).then(res => {
        if (res == "confirm") {
          this.$axios({
            method: "delete",
            url: "/admin/del",
            params: {"adminId": row.adminId}
          }).then(response => {
            if (response.data.code == 200) {
              this.$message.success("删除成功");
              this.reload()
              //this.tableData.splice(department, 1);
            } else {
              this.$message.error("删除失败");
            }
          })
        }
      })
    },

    change(index, row) {
      console.log(row)
      if (row.roleId === 1) {
        this.$message.error("超级管理员无法禁用");
        this.reload()
        return
      }
      this.$axios({
        url: "/admin/edit",
        method: "put",
        data: row
      }).then(response => {
            console.log(response)
          }
      )
    },

    // 分页
    // 显示第几页
    handleCurrentChange(val) {
      // 切换页码时，要获取每页显示的条数
      if (this.page === true) {
        this.getData(this.pageSize, val)
      } else {
        this.showParents(this.pageSize, val)
      }
    },

    close(done) {
      this.admin = {
        adminId: '',
        username: '',
        password: '',
        adminName: '',
        adminStatus: '',
        roleId: '',
        orgId: '',
      }
      done();
    },

    closeInsert() {
      this.admin = {
        adminId: '',
        username: '',
        password: '',
        adminName: '',
        adminStatus: '',
        roleId: '',
        orgId: '',
      }
      this.insertVisible = false;
    },

    closeEdit() {
      this.admin = {
        adminId: '',
        username: '',
        password: '',
        adminName: '',
        adminStatus: '',
        roleId: '',
        orgId: '',
      }
      this.editVisible = false;
    },

  }
}
</script>

<style scoped>
.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 300px;
  display: inline-block;
}

.table {
  width: 100%;
  font-size: 14px;
}

.red {
  color: #ff0000;
}

.mr10 {
  margin-right: 10px;
}

.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
