<template>
  <div>
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <i class="el-icon-lx-cascades"></i> 基础表格
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="container">
      <div class="handle-box">
        <el-button
            type="success"
            icon="el-icon-plus"
            class="handle-del mr10"
            @click="insertVisible = true"
            style="margin-right: 50px"
        >添 加
        </el-button>
      </div>
      <el-table
          :data="tableData"
          border
          class="table"
          ref="multipleTable"
          header-cell-class-name="table-header"
          @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" align="center"></el-table-column>
        <el-table-column
            type="index"
            width="50"
            label="ID"
            align="center">
        </el-table-column>
        <el-table-column prop="roleName" label="角色名称"></el-table-column>
        <el-table-column prop="roleDesc" label="角色描述"></el-table-column>
        <el-table-column label="操作" width="180" align="center">
          <template #default="scope">
            <el-button
                type="text"
                icon="el-icon-edit"
                @click="handleEdit(scope.$index, scope.row)"
            >编辑
            </el-button>
            <el-button
                type="text"
                icon="el-icon-delete"
                class="red"
                @click="handleDelete(scope.$index, scope.row)"
            >删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination background
                     @current-change="handleCurrentChange"
                     :current-page="pageNum"
                     :page-size="pageSize" layout="total, prev, pager, next, jumper"
                     :total="totalCount">
      </el-pagination>
    </div>

    <!-- 添加弹出框 -->
    <el-dialog title="添加" v-model="insertVisible" width="30%" :before-close="close">
      <el-form ref="insertVisible" :model="role" label-width="70px">
        <el-form-item label="角色名称">
          <el-input v-model="role.roleName"></el-input>
        </el-form-item>
        <el-form-item label="角色描述">
          <el-input v-model="role.roleDesc"></el-input>
        </el-form-item>
        <el-form-item label="菜单权限">
          <el-checkbox-group v-model="checkedPermission">
            <el-checkbox v-for="item in permissionData" :label="item.permissionId" :key="item.permissionId">
              {{ item.title }}
            </el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </el-form>
      <template #footer>
                <span class="dialog-footer">
                    <el-button @click="insertVisible = false">取 消</el-button>
                    <el-button type="primary" @click="saveInsert">确 定</el-button>
                </span>
      </template>
    </el-dialog>

    <!-- 编辑弹出框 -->
    <el-dialog title="编辑" v-model="editVisible" width="30%" :before-close="close">
      <el-form ref="editForm" :model="role" label-width="70px">
        <el-form-item label="角色名称">
          <el-input v-model="role.roleName"></el-input>
        </el-form-item>
        <el-form-item label="角色描述">
          <el-input v-model="role.roleDesc"></el-input>
        </el-form-item>
        <el-form-item label="菜单权限">
          <el-checkbox-group v-model="checkedPermission2">
            <el-checkbox v-for="item in permissionData" :label="item.permissionId" :key="item.permissionId">
              {{ item.title }}
            </el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </el-form>
      <template #footer>
                <span class="dialog-footer">
                    <el-button @click="editVisible = false">取 消</el-button>
                    <el-button type="primary" @click="saveEdit">确 定</el-button>
                </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "Role",
  inject: ['reload'],
  data() {
    return {

      tableData: [],

      role: {
        roleId: '',
        roleName: '',
        roleDesc: ''
      },

      permissionData: [],

      checkedPermission: [],

      checkedPermission2: [],


      insertVisible: false,
      editVisible: false,

      // 默认显示第几页
      pageNum: 1,
      // 总条数，根据接口获取数据长度(注意：这里不能为空)
      totalCount: 1,
      // 默认每页显示的条数（可修改）
      pageSize: 10,

      idx: -1,
      id: -1,

    }
  },
  created() {
    this.getData(this.pageSize, this.pageNum)
    this.getAllPermission()
  },
  methods: {

    // 多选操作
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },

    // 获取数据
    getData(pageSize, pageNum) {
      this.$axios({
        method: "get",
        url: "/role/getList",
        params: {"pageSize": pageSize, "pageNum": pageNum}
      }).then(response => {
        console.log(response)
        this.tableData = response.data.datas.rolePageInfo.list
        this.totalCount = response.data.datas.rolePageInfo.total
      })
    },

    // 获取菜单
    getAllPermission() {
      this.$axios({
        method: "get",
        url: "/permission/getList",
      }).then(response => {
        console.log(response)
        this.permissionData = response.data.datas.permissionList
      })
    },


    // 添加
    saveInsert() {
      console.log(this.checkedPermission)
      const map = {};
      map['role'] = this.role
      map['rolePerList'] = this.checkedPermission
      this.$axios({
        method: "post",
        url: "/role/save",
        data: map
      }).then(response => {
        console.log(response)
        if (response.data.code == 200) {
          this.$message.success("添加成功");
          this.reload();
        } else {
          this.$message.error("添加失败");
        }
      })
    },

    // 编辑操作
    handleEdit(index, row) {
      console.log(row)
      this.idx = index;
      this.role = row;
      this.getPermission(row.roleId)
      this.editVisible = true;
    },

    // 获取角色的菜单
    getPermission(roleId) {
      this.$axios({
        method: "get",
        url: "/rolePermission/get",
        params: {"roleId": roleId}
      }).then(response => {
        console.log(response)
        this.checkedPermission2 = response.data.datas.permissionIds
      })
    },

    // 保存修改
    saveEdit() {
      console.log(this.checkedPermission2)
      const map = {};
      map['role'] = this.role
      map['rolePerList'] = this.checkedPermission2
      this.$axios({
        method: "put",
        url: "/role/edit",
        data: map
      }).then(response => {
        console.log(response)
        if (response.data.code == 200) {
          this.$message.success(`修改第 ${this.idx + 1} 行成功`);
          this.reload();
        } else {
          this.$message.error("修改失败");
        }
      })
    },

    // 删除操作
    handleDelete(index, row) {
      // 二次确认删除
      this.$confirm("确定要删除吗？", "提示", {
        type: "warning",
      }).then(res => {
        if (res == "confirm") {
          this.$axios({
            method: "delete",
            url: "/role/del",
            params: {"roleId": row.roleId}
          }).then(response => {
            if (response.data.code == 200) {
              this.$message.success("删除成功");
              this.reload()
              //this.tableData.splice(department, 1);
            } else {
              this.$message.error("删除失败");
            }
          })
        }
      })
    },

    // 分页
    // 显示第几页
    handleCurrentChange(val) {
      // 切换页码时，要获取每页显示的条数
      if (this.page === true) {
        this.getData(this.pageSize, val)
      } else {
        this.showParents(this.pageSize, val)
      }
    },

    close(done) {
      this.role = {
        roleId: '',
        roleName: '',
        roleDesc: ''
      }
      done();
    },

    closeInsert() {
      this.role = {
        roleId: '',
        roleName: '',
        roleDesc: ''
      }
      this.insertVisible = false;
    },

    closeEdit() {
      this.role = {
        roleId: '',
        roleName: '',
        roleDesc: ''
      }
      this.editVisible = false;
    },

  }
}
</script>

<style scoped>
.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 300px;
  display: inline-block;
}

.table {
  width: 100%;
  font-size: 14px;
}

.red {
  color: #ff0000;
}

.mr10 {
  margin-right: 10px;
}

.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
