<template>
  <div>
    <!-- 添加弹出框 -->
      <el-form model="emailConfig" label-width="180px" style="margin-top: 50px">
        <el-form-item label="邮件服务器的SMTP地址">
          <el-input v-model="emailConfig.ecHost" placeholder="若为QQ邮箱填写 smtp.qq.com 即可"></el-input>
        </el-form-item>
        <el-form-item label="邮件服务器的SMTP端口">
          <el-input v-model="emailConfig.ecPort" placeholder="若为QQ邮箱填写 465 即可"></el-input>
        </el-form-item>
        <el-form-item label="发件人邮箱">
          <el-input v-model="emailConfig.ecForm" placeholder="请输入内容"></el-input>
        </el-form-item>
        <el-form-item label="用户名">
          <el-input v-model="emailConfig.ecUser" placeholder="若为QQ邮箱,则用户名与QQ邮箱一致"></el-input>
        </el-form-item>
        <el-form-item label="SMTP授权密码">
          <el-input v-model="emailConfig.ecPass" placeholder="若为QQ邮箱,在设置>账户中开启POP3/SMTP服务获取授权密码"></el-input>
        </el-form-item>
        <el-form-item label="邮件标题">
          <el-input v-model="emailConfig.ecTitle" placeholder="请输入内容"></el-input>
        </el-form-item>
        <el-form-item label="邮件内容">
          <el-input v-model="emailConfig.ecContent" placeholder="出了附件之外的一些邮件文字内容"></el-input>
        </el-form-item>
      </el-form>
    <el-row>
      <el-button type="primary" @click="saveEdit">确 定</el-button>
    </el-row>

  </div>
</template>

<script>
export default {
  name: "EmailConfig",
  inject: ['reload'],
  data() {
    return {

      emailConfig: {
        ecId: '',
        ecHost: '',
        ecPort: '',
        ecForm: '',
        ecUser: '',
        ecPass: '',
        ecTitle: '',
        ecContent: ''
      },

    }
  },
  created() {
    this.getData()
  },
  methods: {
    // 获取数据
    getData() {
      this.$axios({
        method: "get",
        url: "/emailConfig/get",
      }).then(response => {
        console.log(response)
        this.emailConfig = response.data.datas.emailConfig
      })
    },

    // 保存修改
    saveEdit() {
      this.$axios({
        method: "put",
        url: "/emailConfig/edit",
        data: this.emailConfig
      }).then(response => {
        console.log(response)
        if (response.data.code == 200) {
          this.$message.success(`修改成功`);
          this.reload();
        } else {
          this.$message.error("修改失败");
        }
      })
    }

  }
}
</script>

<style scoped>
.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 300px;
  display: inline-block;
}

.table {
  width: 100%;
  font-size: 14px;
}

.red {
  color: #ff0000;
}

.mr10 {
  margin-right: 10px;
}

.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
