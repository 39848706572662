<template>
  <div class="tinymce-editor">
    <Editor v-model="myValue"
            :init="init"
            >
    </Editor>
  </div>
</template>

<script>
import tinymce from 'tinymce/tinymce'
import Editor from '@tinymce/tinymce-vue'
import 'tinymce/themes/silver'
import 'tinymce/plugins/image';
import 'tinymce/plugins/media';
import 'tinymce/plugins/table';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/contextmenu';
import 'tinymce/plugins/wordcount';
import 'tinymce/plugins/colorpicker';
import 'tinymce/plugins/textcolor';
import 'tinymce/icons/default/icons';
// import 'tinymce/plugins/indent2em';

export default {
  name: "Tinymce",
  components: {
    Editor
  },
  props: {
    //传入一个value，使组件支持v-model绑定
    value: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    plugins: {
      type: [String, Array],
      default: 'lists image media table wordcount indent2em'
    },
    toolbar: {
      type: [String, Array],
      default: 'undo redo | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent indent2em| lists image media table | removeformat'
    }
  },
  data() {
    return {
      //初始化配置
      init: {
        language_url: '/tinymce/langs/zh_CN.js',// 语言包的路径
        language: 'zh_CN',//语言
        skin_url: '/tinymce/skins/ui/oxide',// skin路径
        content_css: '/tinymce/skins/content/default/content.css',
        width: 375,
        height: 667,//编辑器高度
        plugins: this.plugins,
        toolbar: this.toolbar,
        branding: false,//是否禁用“Powered by TinyMCE”
        menubar: true,//顶部菜单栏显示
        paste_data_images: true, // 允许粘贴图像
        //此处为图片上传处理函数，这个直接用了base64的图片形式上传图片，
        //如需ajax上传可参考https://www.tiny.cloud/docs/configure/file-image-upload/#images_upload_handler
        images_upload_url: '',
        images_upload_credentials: true,
        images_upload_handler: (blobInfo, success, failure) => {
          console.log(blobInfo.blob(),success)
          let xhr = '';
          let formData = '';
          xhr = new XMLHttpRequest();
          xhr.withCredentials = false;
          xhr.open('POST', `https://yisheng.host/api/pic/upload`);
          xhr.onload = () => {
            let json = {};
            if (xhr.status !== 200) {
              failure('HTTP Error: ', xhr.status);
              return;
            }
            json = JSON.parse(xhr.responseText);
            console.log(json)
            success(json.datas.map.url);
          };
          formData = new FormData();
          formData.append('upFile', blobInfo.blob());
          xhr.send(formData);
            // this.$axios({
            //   method: "upload",
            //   url: "/pic/upload",
            //   data: blobInfo.blob()
            // }).then(response => {
            //   this.orgData = response.data.datas.organizationList
            // })

        }



      },
      myValue: this.value
    }
  },
  mounted() {
    tinymce.init({});
  },
  methods: {
    //添加相关的事件，可用的事件参照文档=> https://github.com/tinymce/tinymce-vue => All available events
    //需要什么事件可以自己增加
    //可以添加一些自己的自定义事件，如清空内容
    clear() {
      this.myValue = ''
    },


  },
  watch: {
    value(newValue) {
      this.myValue = newValue
    },
  }
}

</script>

<style scoped>

</style>
