<template>
  <div>
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <i class="el-icon-lx-cascades"></i> 分享小程序
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="container">
      <div class="handle-box">
        <el-input v-model="creditsShare.csNumber" placeholder="设置用户分享小程序可获得积分" class="handle-input mr10"></el-input>
        <el-button type="primary" @click="handleEdit">确 定</el-button>
      </div>
      <el-table
          :data="creditsShareList"
          border
          class="table"
          ref="multipleTable"
          header-cell-class-name="table-header"
          @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" align="center"></el-table-column>
        <el-table-column
            type="index"
            width="50"
            label="ID"
            align="center">
        </el-table-column>
        <el-table-column prop="csNumber" label="用户分享小程序可获得积分"></el-table-column>
      </el-table>
    </div>

  </div>


  <div>
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <i class="el-icon-lx-cascades"></i> 分享资讯
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="container">
      <div class="handle-box">
        <el-input v-model="newsShare.nsCredits" placeholder="整体设置用户分享资讯可获得积分" class="handle-input mr10"></el-input>
        <el-button type="primary" @click="newsShareEdit">确 定</el-button>
      </div>
      <el-table
          :data="newsShareList"
          border
          class="table"
          ref="multipleTable"
          header-cell-class-name="table-header"
          @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" align="center"></el-table-column>
        <el-table-column
            type="index"
            width="50"
            label="ID"
            align="center">
        </el-table-column>
        <el-table-column prop="nsCredits" label="用户分享资讯可获得积分"></el-table-column>
        <el-table-column prop="nsState" label="是否启用">
          <template v-slot="scope">
            <el-switch
                active-text="启用"
                inactive-text="禁用"
                active-color="#13ce66"
                inactive-color="#ff4949"
                active-value="1"
                inactive-value="0"
                v-model="scope.row.nsState"
                @click=change(scope.$index,scope.row)
            >
            </el-switch>
          </template>
        </el-table-column>
      </el-table>
    </div>

  </div>


  <div>
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <i class="el-icon-lx-cascades"></i> 新用户注册
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="container">
      <div class="handle-box">
        <el-input v-model="creditsRegister.creditsRegister" placeholder="新用户注册小程序可获得积分" class="handle-input mr10"></el-input>
        <el-button type="primary" @click="registerEdit">确 定</el-button>
      </div>
      <el-table
          :data="creditsRegeisterList"
          border
          class="table"
          ref="multipleTable"
          header-cell-class-name="table-header"
          @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" align="center"></el-table-column>
        <el-table-column
            type="index"
            width="50"
            label="ID"
            align="center">
        </el-table-column>
        <el-table-column prop="creditsRegister" label="新用户注册小程序可获得积分"></el-table-column>
      </el-table>
    </div>

  </div>

</template>

<script>
export default {
  name: "CreditsShare",
  inject: ['reload'],
  data() {
    return {

      creditsShareList: [],

      creditsShare: {
        csId: '',
        csNumber: ''
      },

      newsShareList: [],

      newsShare: {
        nsId: '',
        nsCredits: '',
        nsState: ''
      },

      creditsRegeisterList: [],
      creditsRegister:{
        crId: '',
        creditsRegister: ''
      },

      multipleSelection: []
    }
  },
  created() {
    this.getData()
    this.getNewsShare()
    this.getCreditsRegister()
  },
  methods: {
    // 多选操作
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },

    // 获取数据
    getData() {
      this.$axios({
        method: "get",
        url: "/creditsShare/getList",
      }).then(response => {
        this.creditsShareList = response.data.datas.creditsShareList
      })
    },

    handleEdit() {
      this.$axios({
        method: "put",
        url: "/creditsShare/edit",
        data: this.creditsShare
      }).then(response => {
        console.log(response)
        this.reload()
      })
    },

    // 获取数据
    getNewsShare() {
      this.$axios({
        method: "get",
        url: "/newsShare/getList",
      }).then(response => {
        console.log(response)
        this.newsShareList = response.data.datas.newsShareList
      })
    },

    // 获取数据
    getCreditsRegister() {
      this.$axios({
        method: "get",
        url: "/creditsRegister/getList",
      }).then(response => {
        console.log(response)
        this.creditsRegeisterList = response.data.datas.creditsRegisterList
      })
    },

    newsShareEdit() {
      this.$axios({
        method: "put",
        url: "/newsShare/edit",
        data: this.newsShare
      }).then(response => {
        console.log(response)
        this.reload()
      })
    },

    registerEdit(){
      this.$axios({
        method: "put",
        url: "/creditsRegister/edit",
        data: this.creditsRegister
      }).then(response => {
        console.log(response)
        this.reload()
      })
    },

    change(index, row) {
      this.$axios({
        url: "/newsShare/edit",
        method: "put",
        data: row
      }).then(response => {
            console.log(response)
          }
      )
    },

  }
}
</script>

<style scoped>
.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 300px;
  display: inline-block;
}

.table {
  width: 100%;
  font-size: 14px;
}

.red {
  color: #ff0000;
}

.mr10 {
  margin-right: 10px;
}

.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}
</style>
