<template>
  <div>
    <div v-if="page === '1'">
      <div class="crumbs">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item>
            <i class="el-icon-lx-cascades"></i> 基础表格
          </el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="container">
        <div class="handle-box">
          <el-button
              type="success"
              icon="el-icon-plus"
              class="handle-del mr10"
              @click="page = '2'"
              style="margin-right: 50px"
          >添 加
          </el-button>
          <el-input v-model="activity.activityName" placeholder="活动名称" class="handle-input mr10"></el-input>
          <el-button type="primary" icon="el-icon-search" @click="handleSearch">搜索</el-button>
        </div>
        <el-table
            :data="tableData"
            border
            class="table"
            ref="multipleTable"
            header-cell-class-name="table-header"
            @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55" align="center"></el-table-column>
          <el-table-column
              type="index"
              width="50"
              label="ID"
              align="center">
          </el-table-column>
          <el-table-column prop="activityId" label="活动ID"></el-table-column>
          <el-table-column prop="activityName" label="活动名称"></el-table-column>
          <el-table-column prop="activityInfo" label="活动信息"></el-table-column>
          <el-table-column prop="activityPic" label="活动图片">
            <template v-slot="scope">
              <img :src="scope.row.activityPic" :preview-src-list="[scope.row.thumb]" min-width="70" height="70"/>
            </template>
          </el-table-column>
          <el-table-column prop="activityStartTime" label="开始时间"></el-table-column>
          <el-table-column prop="orgId" label="发起机构"></el-table-column>
          <el-table-column prop="activityCredits" label="所需积分"></el-table-column>
          <el-table-column prop="activityTime" label="发布时间"></el-table-column>
          <el-table-column label="操作" width="180" align="center">
            <template #default="scope">
              <el-button
                  type="text"
                  icon="el-icon-edit"
                  @click="handleEdit(scope.$index, scope.row)"
              >编辑
              </el-button>
              <el-button
                  type="text"
                  icon="el-icon-s-custom"
                  @click="showClick(scope.$index, scope.row)"
              >查看参与用户
              </el-button>
              <el-button
                  type="text"
                  icon="el-icon-delete"
                  class="red"
                  @click="handleDelete(scope.$index, scope.row)"
              >删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination background
                       @current-change="handleCurrentChange"
                       :current-page="pageNum"
                       :page-size="pageSize" layout="total, prev, pager, next, jumper"
                       :total="totalCount">
        </el-pagination>
      </div>
    </div>

    <div v-if="page === '2'">
      <el-form model="emailConfig" label-width="180px" style="margin-top: 50px">
        <el-form-item label="活动名称">
          <el-input v-model="activity.activityName"></el-input>
        </el-form-item>
        <el-form-item label="活动信息">
          <el-input
              type="textarea"
              :rows="2"
              placeholder="请输入内容"
              v-model="activity.activityInfo">
          </el-input>
        </el-form-item>
        <div class="block" style="margin-left: 100px;margin-bottom: 30px">
          <span class="demonstration">开始时间</span>
          <el-date-picker
              v-model="activity.activityStartTime"
              type="datetime"
              value-format="yyyy-MM-dd HH:mm:ss"
              style="margin-left: 20px"
              placeholder="选择开始时间">
          </el-date-picker>
        </div>
        <el-form-item label="发起机构">
          <el-select v-model="activity.orgId" placeholder="请选择机构">
            <el-option
                v-for="item in orgData"
                :key="item.orgId"
                :label="item.orgName"
                :value="item.orgId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所需积分">
          <el-input v-model="activity.activityCredits"></el-input>
        </el-form-item>
        <!--      action修改成oss上传图片的路径        -->
        <el-form-item label="活动图片">
          <el-upload
              class="avatar-uploader"
              action="https://yisheng.host/api/activity/upload"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :on-progress="uploadVideoProcess"
          >
            <img v-if="imageUrl" :src="imageUrl" class="avatar">
            <i v-else-if="imageFlag == false" class="el-icon-plus avatar-uploader-icon"></i>
            <el-progress v-if="imageFlag == true" type="circle" :percentage="imageUploadPercent"
                         style="margin-top:30px;"></el-progress>
          </el-upload>
          <p style="color: #DD4A68;margin-left: 10px">建议图片尺寸：355 x 150</p>
        </el-form-item>
        <el-form-item label="活动内容">
          <tinymce_editor ref="editor1"
          >
          </tinymce_editor>
        </el-form-item>
      </el-form>
      <el-row>
        <el-button type="primary" @click="saveInsert">确 定</el-button>
        <el-button type="danger" @click="back">返 回</el-button>
      </el-row>
    </div>

    <div v-if="page === '3'">
      <el-form model="emailConfig" label-width="180px" style="margin-top: 50px">
        <el-form-item label="活动名称">
          <el-input v-model="activity.activityName"></el-input>
        </el-form-item>
        <el-form-item label="活动信息">
          <el-input
              type="textarea"
              :rows="2"
              placeholder="请输入内容"
              v-model="activity.activityInfo">
          </el-input>
        </el-form-item>
        <div class="block" style="margin-left: 100px;margin-bottom: 30px">
          <span class="demonstration">开始时间</span>
          <el-date-picker
              v-model="activity.activityStartTime"
              type="datetime"
              value-format="yyyy-MM-dd HH:mm:ss"
              style="margin-left: 20px"
              placeholder="选择开始时间">
          </el-date-picker>
        </div>
        <el-form-item label="发起机构">
          <el-select v-model="activity.orgId" placeholder="请选择机构">
            <el-option
                v-for="item in orgData"
                :key="item.orgId"
                :label="item.orgName"
                :value="item.orgId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所需积分">
          <el-input v-model="activity.activityCredits"></el-input>
        </el-form-item>
        <!--      action修改成oss上传图片的路径        -->
        <el-form-item label="活动图片">
          <el-upload
              class="avatar-uploader"
              action="https://yisheng.host/api/activity/upload"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :on-progress="uploadVideoProcess"
          >
            <img v-if="imageUrl" :src="imageUrl" class="avatar">
            <i v-else-if="imageFlag == false" class="el-icon-plus avatar-uploader-icon"></i>
            <el-progress v-if="imageFlag == true" type="circle" :percentage="imageUploadPercent"
                         style="margin-top:30px;"></el-progress>
          </el-upload>
          <p style="color: #DD4A68;margin-left: 10px">建议图片尺寸：355 x 150</p>
        </el-form-item>
        <el-form-item label="活动内容">
          <tinymce_editor ref="editor2"
          >
          </tinymce_editor>
        </el-form-item>
      </el-form>
      <el-row>
        <el-button type="primary" @click="saveEdit">确 定</el-button>
        <el-button type="danger" @click="back">返 回</el-button>
      </el-row>
    </div>

    <!--  查看参与用户 -->
    <div class="container" v-if="page === 4">
      <div class="handle-box">
        <el-button type="primary" icon="el-icon-back" @click="back">后 退</el-button>
      </div>
      <el-table
          :data="parentsActivityData"
          border
          class="table"
          ref="multipleTable"
          header-cell-class-name="table-header"
          @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" align="center"></el-table-column>
        <el-table-column
            type="index"
            width="50"
            label="ID"
            align="center">
        </el-table-column>
        <el-table-column prop="contactMan" label="联系人"></el-table-column>
        <el-table-column prop="contactWay" label="联系方式"></el-table-column>
        <el-table-column prop="paInfo" label="信息"></el-table-column>
        <el-table-column label="操作" width="180" align="center">
          <template #default="scope">
            <el-button
                type="text"
                icon="el-icon-view"
                @click="showInfo(scope.$index, scope.row)"
            >查看
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination background
                     @current-change="handleCurrentChange"
                     :current-page="pageNum"
                     :page-size="pageSize" layout="total, prev, pager, next, jumper"
                     :total="totalCount">
      </el-pagination>

      <!-- 添加弹出框 -->
      <el-dialog title="详情" v-model="insertVisible" width="30%">
        <el-form ref="insertVisible" :model="parentsActivity" label-width="70px">
          <el-form-item label="联系人">
            <el-input v-model="parentsActivity.contactMan"></el-input>
          </el-form-item>
          <el-form-item label="联系方式">
            <el-input v-model="parentsActivity.contactWay"></el-input>
          </el-form-item>
          <el-form-item label="信息">
            <el-input type="textarea"
                      :autosize="{ minRows: 2, maxRows: 4}"
                      v-model="parentsActivity.paInfo"></el-input>
          </el-form-item>
        </el-form>
        <div class="demo-image__placeholder">
          <div class="block">
            <span class="demonstration"></span>
            <el-image :src="parentsActivity.paPic"></el-image>
          </div>
        </div>
        <template #footer>
                <span class="dialog-footer">
                    <el-button type="primary" @click="insertVisible = false">取 消</el-button>
                </span>
        </template>
      </el-dialog>
    </div>


  </div>
</template>

<script>
import TinyMce from '../../components/Tinymce/Tinymce.vue';

export default {
  name: "Activity",
  inject: ['reload'],
  components: {
    tinymce_editor: TinyMce,
  },
  data() {
    return {
      tableData: [],

      orgData: [],

      parentsActivityData: [],
      srcList: [],

      activity: {
        activityId: '',
        activityInfo: '',
        activityName: '',
        activityDesc: '',
        activityTime: '',
        orgId: '',
        activityCredits: '',
        activityStartTime: '',
        activityPic: ''
      },

      imageUrl: '',

      activityStartTime2: '',

      insertVisible: false,
      parentsActivity: {
        contactMan: '',
        contactWay: '',
        paInfo: '',
        paPic: ''
      },

      page: '1',

      // 默认显示第几页
      pageNum: 1,
      // 总条数，根据接口获取数据长度(注意：这里不能为空)
      totalCount: 1,
      // 默认每页显示的条数（可修改）
      pageSize: 10,

      idx: -1,
      id: -1,

      imageFlag: false,
      imageUploadPercent: ''
    }
  },
  created() {
    this.getOrg(-1, 0)
    this.getData(this.pageSize, this.pageNum)
  },
  methods: {

    // 多选操作
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },

    // 获取数据
    getData(pageSize, pageNum) {
      this.$axios({
        method: "get",
        url: "/activity/getList2",
        params: {"activityName": this.activity.activityName, "pageSize": pageSize, "pageNum": pageNum}
      }).then(response => {
        this.tableData = response.data.datas.activityPageInfo.list
        // 修改表格中的orgId为orgName的值
        for (let i = 0; i < this.tableData.length; i++) {
          for (let j = 0; j < this.orgData.length; j++) {
            if (this.tableData[i].orgId == this.orgData[j].orgId) {
              this.tableData[i].orgId = this.orgData[j].orgName
              this.tableData[i].orgId2 = this.orgData[j].orgId
            }
          }
        }
        this.totalCount = response.data.datas.activityPageInfo.total
      })
    },


    // 获取所有的机构下拉选择
    getOrg(pageSize, pageNum) {
      this.$axios({
        method: "get",
        url: "/org/getList",
        params: {"orgName": '', "pageSize": pageSize, "pageNum": pageNum}
      }).then(response => {
        this.orgData = response.data.datas.organizationList
      })
    },

    // 搜索
    handleSearch() {
      this.getData(this.pageSize, this.pageNum)
    },

    // 添加
    saveInsert() {
      this.activity.activityPic = this.imageUrl
      this.activity.activityDesc = this.$refs.editor1.myValue
      if (this.activity.activityStartTime != '') {
        this.activity.activityStartTime = this.activity.activityStartTime.getFullYear() + '-' + (this.activity.activityStartTime.getMonth() + 1) + '-' + this.activity.activityStartTime.getDate() + ' ' + this.activity.activityStartTime.getHours() + ':' + this.activity.activityStartTime.getMinutes() + ':' + this.activity.activityStartTime.getSeconds()
      }
      console.log(this.activity.activityStartTime)
      this.$axios({
        method: "post",
        url: "/activity/save",
        data: this.activity
      }).then(response => {
        if (response.data.code == 200) {
          this.$message.success("添加成功");
          this.reload();
        } else {
          this.$message.error("添加失败");
        }
      })
    },

    // 编辑操作
    handleEdit(index, row) {
      this.idx = index;
      this.activity = row;
      this.activity.orgId = row.orgId2;
      this.page = "3"
      this.imageUrl = row.activityPic
      this.activityStartTime2 = this.activity.activityStartTime
      this.$nextTick(() => {
        this.$refs.editor2.myValue = row.activityDesc
      })
    },

    // 保存修改
    saveEdit() {
      console.log(this.activity)
      this.activity.activityDesc = this.$refs.editor2.myValue
      this.activity.activityPic = this.imageUrl
      console.log(this.activityStartTime2)
      console.log(this.activity.activityStartTime)
      if (this.activity.activityStartTime !== this.activityStartTime2) {
        this.activity.activityStartTime = this.activity.activityStartTime.getFullYear() + '-' + (this.activity.activityStartTime.getMonth() + 1) + '-' + this.activity.activityStartTime.getDate() + ' ' + this.activity.activityStartTime.getHours() + ':' + this.activity.activityStartTime.getMinutes() + ':' + this.activity.activityStartTime.getSeconds()
      }
      this.$axios({
        method: "put",
        url: "/activity/edit",
        data: this.activity
      }).then(response => {
        if (response.data.code == 200) {
          this.$message.success(`修改第 ${this.idx + 1} 行成功`);
          this.reload();
        } else {
          this.$message.error("修改失败");
        }
      })
    },

    // 删除操作
    handleDelete(index, row) {
      // 二次确认删除
      this.$confirm("确定要删除吗？", "提示", {
        type: "warning",
      }).then(res => {
        if (res == "confirm") {
          this.$axios({
            method: "delete",
            url: "/activity/del",
            params: {"activityId": row.activityId}
          }).then(response => {
            if (response.data.code == 200) {
              this.$message.success("删除成功");
              this.reload()
              //this.tableData.splice(department, 1);
            } else {
              this.$message.error("删除失败");
            }
          })
        }
      })
    },

    // 分页
    // 显示第几页
    handleCurrentChange(val) {
      // 切换页码时，要获取每页显示的条数
      if (this.page === 1) {
        this.getData(this.pageSize, val)
      } else if (this.page === 4) {
        this.showParents(this.pageSize, val)
      }
    },

    uploadVideoProcess(event, file) {
      this.imageFlag = true;
      this.imageUploadPercent = file.percentage.toFixed(0);
    },
    // 上传图片
    handleAvatarSuccess(res) {
      this.imageFlag = false;
      this.imageUploadPercent = 0;
      this.imageUrl = res.datas.map.url
    },

    showClick(index, row) {
      this.page = 4
      this.activity.activityId = row.activityId
      this.showParents(this.pageSize, this.pageNum)
    },

    showParents(pageSize, pageNum) {
      this.$axios({
        method: "get",
        url: "/activityApply/getList",
        params: {"activityId": this.activity.activityId, "pageSize": pageSize, "pageNum": pageNum}
      }).then(response => {
        console.log(response)
        this.parentsActivityData = response.data.datas.parentsActivityPageInfo.list
        this.parentsActivityData.map((item) => {
          this.srcList.push(item.paPic)
        });
        this.totalCount = response.data.datas.parentsActivityPageInfo.total
      })
    },

    back() {
      this.page = "1"
      this.reload()
    },

    // 查看
    showInfo(index,row){
      this.insertVisible = true
      this.parentsActivity = row
      console.log(row)
    }

  }
}
</script>

<style scoped>
.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 300px;
  display: inline-block;
}

.table {
  width: 100%;
  font-size: 14px;
}

.red {
  color: #ff0000;
}

.mr10 {
  margin-right: 10px;
}

.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
