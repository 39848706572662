<template>
  <div>

    <div v-if="page === 1">
      <div class="crumbs">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item>
            <i class="el-icon-lx-cascades"></i> 基础表格
          </el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="container">
        <div class="handle-box">
          <el-button
              type="success"
              icon="el-icon-plus"
              class="handle-del mr10"
              style="margin-right: 50px"
              @click="page = 4"
          >添 加
          </el-button>
          <el-input v-model="organization.orgName" placeholder="机构名称" class="handle-input mr10"></el-input>
          <el-button type="primary" icon="el-icon-search" @click="handleSearch">搜索</el-button>
        </div>
        <el-table
            :data="tableData"
            border
            class="table"
            ref="multipleTable"
            header-cell-class-name="table-header"
            @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55" align="center"></el-table-column>
          <el-table-column
              type="index"
              width="50"
              label="ID"
              align="center">
          </el-table-column>
          <el-table-column prop="orgName" label="机构名称"></el-table-column>
          <el-table-column prop="orgPic" label="机构图片">
            <template v-slot="scope">
              <img :src="scope.row.orgPic" :preview-src-list="[scope.row.thumb]" min-width="70" height="70"/>
            </template>
          </el-table-column>
          <el-table-column prop="orgInfo" label="机构信息"></el-table-column>
          <el-table-column prop="orgLinkman" label="联系人"></el-table-column>
          <el-table-column prop="orgPhone" label="机构电话"></el-table-column>
          <el-table-column prop="openTime" label="营业开始时间"></el-table-column>
          <el-table-column prop="closeTime" label="营业结束时间"></el-table-column>
          <el-table-column prop="orgProvince" label="省"></el-table-column>
          <el-table-column prop="orgCity" label="市"></el-table-column>
          <el-table-column prop="orgDistrict" label="区"></el-table-column>
          <el-table-column prop="orgSite" label="详细地址"></el-table-column>
          <el-table-column prop="qrCode" label="机构二维码">
            <template v-slot="scope">
              <span v-if="scope.row.qrCode !== null">
                <a :href="scope.row.qrCode">
                  <el-button type="primary">下载二维码</el-button>
                </a>
              </span>
              <span v-if="scope.row.qrCode === null">
                <el-tag type="danger">暂无二维码</el-tag>
              </span>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="180" align="center">
            <template #default="scope">
              <el-button
                  type="text"
                  icon="el-icon-edit"
                  @click="handleEdit(scope.$index, scope.row)"
              >编辑
              </el-button>
              <el-button
                  type="text"
                  icon="el-icon-delete"
                  class="red"
                  @click="handleDelete(scope.$index, scope.row)"
              >删除
              </el-button>
              <el-button
                  type="text"
                  icon="el-icon-user-solid"
                  @click="showClick(scope.$index, scope.row)"
              >查看用户
              </el-button>
              <el-button
                  type="text"
                  icon="el-icon-user-solid"
                  @click="showClerk(scope.$index, scope.row)"
              >查看终身学习导师
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination background
                       @current-change="handleCurrentChange"
                       :current-page="pageNum"
                       :page-size="pageSize" layout="total, prev, pager, next, jumper"
                       :total="totalCount">
        </el-pagination>
      </div>
    </div>

    <!--    <div class="container" v-if="page === 2">-->
    <!--      <div class="crumbs">-->
    <!--        <el-breadcrumb separator="/">-->
    <!--          <el-breadcrumb-item>-->
    <!--            <i class="el-icon-lx-cascades"></i> 基础表格-->
    <!--          </el-breadcrumb-item>-->
    <!--        </el-breadcrumb>-->
    <!--      </div>-->
    <!--      <div class="handle-box">-->
    <!--        <el-button type="primary" icon="el-icon-back" @click="back">后 退</el-button>-->
    <!--      </div>-->
    <!--      <el-table-->
    <!--          :data="clerkData"-->
    <!--          border-->
    <!--          class="table"-->
    <!--          ref="multipleTable"-->
    <!--          header-cell-class-name="table-header"-->
    <!--          @selection-change="handleSelectionChange"-->
    <!--      >-->
    <!--        <el-table-column type="selection" width="55" align="center"></el-table-column>-->
    <!--        <el-table-column-->
    <!--            type="index"-->
    <!--            width="50"-->
    <!--            label="ID"-->
    <!--            align="center">-->
    <!--        </el-table-column>-->
    <!--        <el-table-column prop="clerkName" label="终身学习导师名称"></el-table-column>-->
    <!--        <el-table-column prop="clerkIcon" label="头像">-->
    <!--          <template v-slot="scope">-->
    <!--            <img :src="scope.row.clerkIcon" :preview-src-list="[scope.row.thumb]" min-width="70" height="70"/>-->
    <!--          </template>-->
    <!--        </el-table-column>-->
    <!--        <el-table-column prop="orgId" label="所属机构"></el-table-column>-->
    <!--        <el-table-column prop="clerkTime" label="注册时间"></el-table-column>-->
    <!--      </el-table>-->

    <!--      <el-pagination background-->
    <!--                     @current-change="handleCurrentChange"-->
    <!--                     :current-page="pageNum"-->
    <!--                     :page-size="pageSize" layout="total, prev, pager, next, jumper"-->
    <!--                     :total="totalCount">-->
    <!--      </el-pagination>-->
    <!--    </div>-->

    <div class="container" v-if="page === 3">
      <div class="crumbs">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item>
            <i class="el-icon-lx-cascades"></i> 基础表格
          </el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="handle-box">
        <el-button type="primary" icon="el-icon-back" @click="back">后 退</el-button>
      </div>
      <el-table
          :data="parentsData"
          border
          class="table"
          ref="multipleTable"
          header-cell-class-name="table-header"
          @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" align="center"></el-table-column>
        <el-table-column
            type="index"
            width="50"
            label="ID"
            align="center">
        </el-table-column>
        <el-table-column prop="parentsName" label="智慧父母昵称"></el-table-column>
        <el-table-column prop="parentsIcon" label="头像">
          <template v-slot="scope">
            <img :src="scope.row.parentsIcon" :preview-src-list="[scope.row.thumb]" min-width="70" height="70"/>
          </template>
        </el-table-column>
        <el-table-column prop="parentsPhone" label="手机号"></el-table-column>
        <el-table-column prop="orgId" label="所属机构"></el-table-column>
        <el-table-column prop="parentsTime" label="注册时间"></el-table-column>
      </el-table>

      <el-pagination background
                     @current-change="handleCurrentChange"
                     :current-page="pageNum"
                     :page-size="pageSize" layout="total, prev, pager, next, jumper"
                     :total="totalCount">
      </el-pagination>
    </div>

    <!-- 添加页面 -->
    <div v-if="page === 4">
      <el-form ref="insertVisible" :model="organization" label-width="70px">
        <el-form-item label="机构名称">
          <el-input v-model="organization.orgName"></el-input>
        </el-form-item>

        <!--      action修改成oss上传图片的路径        -->
        <el-form-item label="机构图片">
          <el-upload
              class="avatar-uploader"
              action="https://yisheng.host/api/org/upload"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :on-progress="uploadVideoProcess"
          >
            <img v-if="imageUrl" :src="imageUrl" class="avatar">
            <i v-else-if="imageFlag === false" class="el-icon-plus avatar-uploader-icon"></i>
            <el-progress v-if="imageFlag === true" type="circle" :percentage="imageUploadPercent"
                         style="margin-top:30px;"></el-progress>
          </el-upload>
          <p style="color: #DD4A68;margin-left: 10px">建议图片尺寸：42 x 42</p>
        </el-form-item>
        <el-form-item label="机构信息">
          <el-input
              type="textarea"
              :rows="2"
              placeholder="请输入内容"
              v-model="organization.orgInfo">
          </el-input>
        </el-form-item>
        <el-form-item label="机构简介">
          <tinymce_editor ref="editor1"
          >
          </tinymce_editor>
        </el-form-item>
        <div class="block" style="margin-bottom: 25px">
          <span class="demonstration" style="font-size: 14px;">营业时间</span>
          <span style="margin-left: 15px">
            <el-time-picker
                is-range
                v-model="timeValue"
                range-separator="至"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
                placeholder="选择时间范围">
            </el-time-picker>
          </span>
        </div>
        <el-form-item label="联系人">
          <el-input v-model="organization.orgLinkman"></el-input>
        </el-form-item>
        <el-form-item label="机构电话">
          <el-input v-model="organization.orgPhone"></el-input>
        </el-form-item>
        <el-form-item label="省">
          <el-input v-model="organization.orgProvince"></el-input>
        </el-form-item>
        <el-form-item label="市">
          <el-input @input="addressSearch" v-model="organization.orgCity"></el-input>
        </el-form-item>
        <el-form-item label="区">
          <el-input v-model="organization.orgDistrict"></el-input>
        </el-form-item>
        <el-form-item label="详细地址">
          <el-input @input="addressSearch" v-model="organization.orgSite"></el-input>
        </el-form-item>
      </el-form>
      <div style="text-align: center;margin-bottom: 30px">
        <iframe style="width: 1000px;height:500px;"
                :src="'https://m.amap.com/navi/?dest='+pois.location+'&destName='+pois.pname+ pois.cityname + pois.adname + pois.name +'&key=ab916a8687ff326e8764cfb303c87977'"></iframe>
      </div>
      <el-row>
        <el-button type="primary" @click="saveInsert">确 定</el-button>
        <el-button type="danger" @click="back">返 回</el-button>
      </el-row>
    </div>


    <!-- 编辑页面 -->
    <div v-if="page === 5">
      <el-form ref="insertVisible" :model="organization" label-width="70px">
        <el-form-item label="机构名称">
          <el-input v-model="organization.orgName"></el-input>
        </el-form-item>

        <!--      action修改成oss上传图片的路径        -->
        <el-form-item label="机构图片">
          <el-upload
              class="avatar-uploader"
              action="https://yisheng.host/api/org/upload"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :on-progress="uploadVideoProcess"
          >
            <img v-if="imageUrl" :src="imageUrl" class="avatar">
            <i v-else-if="imageFlag === false" class="el-icon-plus avatar-uploader-icon"></i>
            <el-progress v-if="imageFlag === true" type="circle" :percentage="imageUploadPercent"
                         style="margin-top:30px;"></el-progress>
          </el-upload>
          <p style="color: #DD4A68;margin-left: 10px">建议图片尺寸：42 x 42</p>
        </el-form-item>
        <el-form-item label="机构信息">
          <el-input
              type="textarea"
              :rows="2"
              placeholder="请输入内容"
              v-model="organization.orgInfo">
          </el-input>
        </el-form-item>
        <el-form-item label="机构简介">
          <tinymce_editor ref="editor2"
          >
          </tinymce_editor>
        </el-form-item>
        <div class="block" style="margin-bottom: 25px">
          <span class="demonstration" style="font-size: 14px;">营业时间</span>
          <span style="margin-left: 15px">
            <el-time-picker
                is-range
                v-model="timeValue"
                range-separator="至"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
                placeholder="选择时间范围">
            </el-time-picker>
          </span>
        </div>
        <el-form-item label="联系人">
          <el-input v-model="organization.orgLinkman"></el-input>
        </el-form-item>
        <el-form-item label="机构电话">
          <el-input v-model="organization.orgPhone"></el-input>
        </el-form-item>
        <el-form-item label="省">
          <el-input v-model="organization.orgProvince"></el-input>
        </el-form-item>
        <el-form-item label="市">
          <el-input @input="addressSearch" v-model="organization.orgCity"></el-input>
        </el-form-item>
        <el-form-item label="区">
          <el-input v-model="organization.orgDistrict"></el-input>
        </el-form-item>
        <el-form-item label="详细地址">
          <el-input @input="addressSearch" v-model="organization.orgSite"></el-input>
        </el-form-item>
      </el-form>
      <div style="text-align: center;margin-bottom: 30px">
        <iframe style="width: 1000px;height:500px;"
                :src="'https://m.amap.com/navi/?dest='+pois.location+'&destName='+pois.pname+ pois.cityname + pois.adname + pois.name +'&key=ab916a8687ff326e8764cfb303c87977'"></iframe>
      </div>
      <el-row>
        <el-button type="primary" @click="saveEdit">确 定</el-button>
        <el-button type="danger" @click="back">返 回</el-button>
      </el-row>
    </div>

  </div>
</template>

<script>
import TinyMce from '../../components/Tinymce/Tinymce.vue';

export default {
  name: "Org",
  inject: ['reload'],
  components: {
    tinymce_editor: TinyMce,
  },
  data() {
    return {
      page: 1,

      tableData: [],

      clerkData: [],

      parentsData: [],

      organization: {
        orgId: '',
        orgInfo: '',
        orgName: '',
        orgPic: '',
        orgDesc: '',
        orgLinkman: '',
        orgPhone: '',
        orgProvince: '',
        orgCity: '',
        orgDistrict: '',
        orgSite: '',
        openTime: '',
        closeTime: '',
        lng: '',
        lat: '',
        qrCode: ''
      },

      identity: '',

      timeValue: [],

      imageUrl: '',

      // 默认显示第几页
      pageNum: 1,
      // 总条数，根据接口获取数据长度(注意：这里不能为空)
      totalCount: 1,
      // 默认每页显示的条数（可修改）
      pageSize: 10,

      idx: -1,
      id: -1,

      imageFlag: false,
      imageUploadPercent: '',

      // 地图搜索返回信息
      pois: {
        location: "104.06,30.67",
        pname: "",
        cityname: "成都市",
        adname: "",
        name: ""
      }
    }
  },
  created() {
    // 机构用户登录仅显示本机构信息
    let orgId = localStorage.getItem("orgId")
    console.log(orgId)
    if (orgId != null && orgId != '' && orgId != "null") {
      console.log("执行有问题")
      this.$axios({
        method: "get",
        url: "/org/get",
        params: {"orgId": orgId}
      }).then(response => {
        console.log(response)
        this.tableData.push(response.data.datas.organization);
      })
    } else {
      this.getData(this.pageSize, this.pageNum)
    }
  },
  methods: {

    // 多选操作
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },

    // 获取数据
    getData(pageSize, pageNum) {
      this.$axios({
        method: "get",
        url: "/org/getList",
        params: {"orgName": this.organization.orgName, "pageSize": pageSize, "pageNum": pageNum}
      }).then(response => {
        console.log(response)
        this.tableData = response.data.datas.organizationPageInfo.list
        this.totalCount = response.data.datas.organizationPageInfo.total
      })
    },

    // 搜索
    handleSearch() {
      let orgId = localStorage.getItem("orgId")
      if (orgId != null && orgId != '' && orgId != "null") {
        return;
      }
      this.getData(this.pageSize, this.pageNum)
    },

    // 添加
    saveInsert() {
      this.organization.orgPic = this.imageUrl
      if (this.timeValue.length > 0) {
        let hours = this.timeValue[0].getHours()
        if (hours < 10) {
          hours = '0' + hours
        }
        let minutes = this.timeValue[0].getMinutes()
        if (minutes < 10) {
          minutes = '0' + minutes
        }
        this.organization.openTime = hours + ':' + minutes
      }
      if (this.timeValue.length > 0) {
        let hours = this.timeValue[1].getHours()
        if (hours < 10) {
          hours = '0' + hours
        }
        let minutes = this.timeValue[1].getMinutes()
        if (minutes < 10) {
          minutes = '0' + minutes
        }
        this.organization.closeTime = hours + ':' + minutes
      }
      if (this.pois.location !== null) {
        let array = this.pois.location.split(',')
        this.organization.lng = array[0]
        this.organization.lat = array[1]
      }
      this.organization.orgDesc = this.$refs.editor1.myValue
      this.$axios({
        method: "post",
        url: "/org/save",
        data: this.organization
      }).then(response => {
        if (response.data.code === 200) {
          this.$message.success("添加成功");
          this.reload();
        } else {
          this.$message.error("添加失败");
        }
      })
    },

    // 编辑操作
    handleEdit(index, row) {
      this.idx = index;
      this.organization = row;
      this.page = 5;
      this.imageUrl = row.orgPic;
      // 地图赋值
      this.addressSearch();
      this.$nextTick(() => {
        this.$refs.editor2.myValue = row.orgDesc
      })
    },
    // 保存修改
    saveEdit() {
      this.organization.orgPic = this.imageUrl
      if (this.timeValue.length > 0) {
        let hours = this.timeValue[0].getHours()
        if (hours < 10) {
          hours = '0' + hours
        }
        let minutes = this.timeValue[0].getMinutes()
        if (minutes < 10) {
          minutes = '0' + minutes
        }
        this.organization.openTime = hours + ':' + minutes
      }
      if (this.timeValue.length > 0) {
        let hours = this.timeValue[1].getHours()
        if (hours < 10) {
          hours = '0' + hours
        }
        let minutes = this.timeValue[1].getMinutes()
        if (minutes < 10) {
          minutes = '0' + minutes
        }
        this.organization.closeTime = hours + ':' + minutes
      }
      if (this.pois.location !== null) {
        console.log(this.pois.location)
        let array = this.pois.location.split(',')
        this.organization.lng = array[0]
        this.organization.lat = array[1]
      }
      this.organization.orgDesc = this.$refs.editor2.myValue
      this.$axios({
        method: "put",
        url: "/org/edit",
        data: this.organization
      }).then(response => {
        console.log(response)
        if (response.data.code === 200) {
          this.$message.success(`修改第 ${this.idx + 1} 行成功`);
          this.reload();
        } else {
          this.$message.error("修改失败");
        }
      })
    },

    showClerk(index, row) {
      this.page = 3
      this.organization.orgId = row.orgId
      this.organization.orgName = row.orgName
      this.identity = "终身学习导师"
      this.showParents(this.pageSize, this.pageNum)
    },

    showClick(index, row) {
      this.page = 3
      this.organization.orgId = row.orgId
      this.organization.orgName = row.orgName
      this.identity = "智慧父母"
      this.showParents(this.pageSize, this.pageNum)
    },

    // 获取数据
    showParents(pageSize, pageNum) {
      console.log("终身学习导师还是智慧父母")
      console.log(this.identity)
      this.$axios({
        method: "get",
        url: "/parent/getListByOrg",
        params: {"orgId": this.organization.orgId, "identity": this.identity, "pageSize": pageSize, "pageNum": pageNum}
      }).then(response => {
        console.log("打印用户数据")
        console.log(response)
        this.parentsData = response.data.datas.parentsPageInfo.list
        this.totalCount = response.data.datas.parentsPageInfo.total
        // 修改表格中的orgId为orgName的值
        for (let i = 0; i < this.parentsData.length; i++) {
          this.parentsData[i].orgId = this.organization.orgName
        }
      })
    },

    // 展示下载机构二维码
    showQrCode(index, row) {
      console.log(index, row)
    },

    // 删除操作
    handleDelete(index, row) {
      // 二次确认删除
      this.$confirm("确定要删除吗？", "提示", {
        type: "warning",
      }).then(res => {
        if (res == "confirm") {
          this.$axios({
            method: "delete",
            url: "/org/del",
            params: {"orgId": row.orgId}
          }).then(response => {
            if (response.data.code == 200) {
              this.$message.success("删除成功");
              this.reload()
              //this.tableData.splice(department, 1);
            } else {
              this.$message.error("删除失败");
            }
          })
        }
      })
    },

    // 分页
    // 显示第几页
    handleCurrentChange(val) {
      // 切换页码时，要获取每页显示的条数
      if (this.page === 1) {
        this.getData(this.pageSize, val)
      } else {
        this.showParents(this.pageSize, val)
      }
    },

    back() {
      this.page = 1
      this.reload()
    },

    uploadVideoProcess(event, file) {
      this.imageFlag = true;
      this.imageUploadPercent = file.percentage.toFixed(0);
    },
    // 上传图片
    handleAvatarSuccess(res) {
      this.imageFlag = false;
      this.imageUploadPercent = 0;
      this.imageUrl = res.datas.map.url
    },

    addressSearch() {
      this.$axios({
        method: "get",
        url: "https://restapi.amap.com/v3/place/text",
        params: {
          "key": 'ab916a8687ff326e8764cfb303c87977',
          "keywords": this.organization.orgSite,
          "city": this.organization.orgCity,
          "offset": 1
        }
      }).then(response => {
        if (response.data.pois[0] != null) {
          this.pois = response.data.pois[0]
        }
        console.log(response)
      })
    },

  }
}
</script>

<style scoped>
.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 300px;
  display: inline-block;
}

.table {
  width: 100%;
  font-size: 14px;
}

.red {
  color: #ff0000;
}

.mr10 {
  margin-right: 10px;
}

.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
