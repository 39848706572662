<template>
  <div>
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <i class="el-icon-lx-cascades"></i> 基础表格
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="container">
      <div class="handle-box">
        <el-button
            type="success"
            icon="el-icon-plus"
            class="handle-del mr10"
            @click="insertVisible = true"
            style="margin-right: 50px"
        >添 加
        </el-button>
      </div>
      <el-table
          :data="tableData"
          border
          class="table"
          ref="multipleTable"
          header-cell-class-name="table-header"
          @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" align="center"></el-table-column>
        <el-table-column
            type="index"
            width="50"
            label="ID"
            align="center">
        </el-table-column>
        <el-table-column prop="etName" label="真题类型"></el-table-column>
        <el-table-column prop="etIcon" label="真题类型图标">
          <template v-slot="scope">
            <img :src="scope.row.etIcon" :preview-src-list="[scope.row.thumb]" min-width="70" height="70"/>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="180" align="center">
          <template #default="scope">
            <el-button
                type="text"
                icon="el-icon-edit"
                @click="handleEdit(scope.$index, scope.row)"
            >编辑
            </el-button>
            <el-button
                type="text"
                icon="el-icon-delete"
                class="red"
                @click="handleDelete(scope.$index, scope.row)"
            >删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination background
                     @current-change="handleCurrentChange"
                     :current-page="pageNum"
                     :page-size="pageSize" layout="total, prev, pager, next, jumper"
                     :total="totalCount">
      </el-pagination>
    </div>

    <!-- 添加弹出框 -->
    <el-dialog title="添加" v-model="insertVisible" width="30%" :before-close="close">
      <el-form ref="editVisible" :model="examType" label-width="100px">
        <el-form-item label="真题类型">
          <el-input v-model="examType.etName"></el-input>
        </el-form-item>
        <el-form-item label="真题类型图标">
          <el-upload
              class="avatar-uploader"
              action="https://yisheng.host/api/activity/upload"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :on-progress="uploadVideoProcess"
          >
            <img v-if="imageUrl" :src="imageUrl" class="avatar">
            <i v-else-if="imageFlag == false" class="el-icon-plus avatar-uploader-icon"></i>
            <el-progress v-if="imageFlag == true" type="circle" :percentage="imageUploadPercent"
                         style="margin-top:30px;"></el-progress>
          </el-upload>
          <p style="color: #DD4A68;margin-left: 10px">建议图片尺寸：42 x 42</p>
        </el-form-item>
      </el-form>
      <template #footer>
                <span class="dialog-footer">
                    <el-button @click="closeInsert">取 消</el-button>
                    <el-button type="primary" @click="saveInsert">确 定</el-button>
                </span>
      </template>
    </el-dialog>

    <!-- 添加弹出框 -->
    <el-dialog title="编辑" v-model="editVisible" width="30%" :before-close="close">
      <el-form ref="editVisible" :model="examType" label-width="100px">
        <el-form-item label="真题类型">
          <el-input v-model="examType.etName"></el-input>
        </el-form-item>
        <el-form-item label="真题类型图标">
          <el-upload
              class="avatar-uploader"
              action="https://yisheng.host/api/activity/upload"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :on-progress="uploadVideoProcess"
          >
            <img v-if="imageUrl" :src="imageUrl" class="avatar">
            <i v-else-if="imageFlag == false" class="el-icon-plus avatar-uploader-icon"></i>
            <el-progress v-if="imageFlag == true" type="circle" :percentage="imageUploadPercent"
                         style="margin-top:30px;"></el-progress>
          </el-upload>
          <p style="color: #DD4A68;margin-left: 10px">建议图片尺寸：42 x 42</p>
        </el-form-item>
      </el-form>
      <template #footer>
                <span class="dialog-footer">
                    <el-button @click="closeEdit">取 消</el-button>
                    <el-button type="primary" @click="saveEdit">确 定</el-button>
                </span>
      </template>
    </el-dialog>

  </div>

</template>

<script>
export default {
  name: "ExamType",
  inject: ['reload'],
  data() {
    return {
      tableData: [],

      examType: {
        etId: '',
        etName: '',
        etIcon: ''
      },

      imageUrl: '',

      insertVisible: false,
      editVisible: false,

      // 默认显示第几页
      pageNum: 1,
      // 总条数，根据接口获取数据长度(注意：这里不能为空)
      totalCount: 1,
      // 默认每页显示的条数（可修改）
      pageSize: 10,

      idx: -1,
      id: -1,

      imageFlag: false,
      imageUploadPercent: ''
    }
  },
  created() {
    this.getData(this.pageSize, this.pageNum)
  },
  methods: {

    // 多选操作
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },

    // 获取数据
    getData(pageSize, pageNum) {
      this.$axios({
        method: "get",
        url: "/examType/getList",
        params: {"pageSize": pageSize, "pageNum": pageNum}
      }).then(response => {
        console.log(response)
        this.tableData = response.data.datas.examTypePageInfo.list
        this.totalCount = response.data.datas.examTypePageInfo.total
      })
    },

    // 搜索
    handleSearch() {
      this.getData(this.pageSize, this.pageNum)
    },

    close(done) {
      this.examType = {
        etId: '',
        etName: '',
        etIcon: ''
      },
          this.imageUrl = '',
          done();
    },

    closeInsert() {
      this.examType = {
        etId: '',
        etName: '',
        etIcon: ''
      },
          this.insertVisible = false;
      this.imageUrl = '';
    },

    closeEdit() {
      this.examType = {
        etId: '',
        etName: '',
        etIcon: ''
      },
          this.editVisible = false;
      this.imageUrl = '';
    },

    // 添加
    saveInsert() {
      this.examType.etIcon = this.imageUrl
      this.$axios({
        method: "post",
        url: "/examType/save",
        data: this.examType
      }).then(response => {
        console.log(response)
        if (response.data.code == 200) {
          this.$message.success("添加成功");
          this.reload();
        } else {
          this.$message.error("添加失败");
        }
      })
    },

    // 编辑操作
    handleEdit(index, row) {
      console.log(row)
      this.idx = index;
      this.examType = row;
      this.editVisible = true;
      this.imageUrl = row.etIcon;
    },
    // 保存修改
    saveEdit() {
      this.examType.etIcon = this.imageUrl
      this.$axios({
        method: "put",
        url: "/examType/edit",
        data: this.examType
      }).then(response => {
        console.log(response)
        if (response.data.code == 200) {
          this.$message.success(`修改第 ${this.idx + 1} 行成功`);
          this.reload();
        } else {
          this.$message.error("修改失败");
        }
      })
    },

    // 删除操作
    handleDelete(index, row) {
      // 二次确认删除
      this.$confirm("确定要删除吗？", "提示", {
        type: "warning",
      }).then(res => {
        if (res == "confirm") {
          this.$axios({
            method: "delete",
            url: "/examType/del",
            params: {"etId": row.etId}
          }).then(response => {
            if (response.data.code == 200) {
              this.$message.success("删除成功");
              this.reload()
              //this.tableData.splice(department, 1);
            } else {
              this.$message.error("删除失败");
            }
          })
        }
      })
    },

    // 分页
    // 显示第几页
    handleCurrentChange(val) {
      // 切换页码时，要获取每页显示的条数
      this.getData(this.pageSize, val)
    },

    uploadVideoProcess(event, file) {
      this.imageFlag = true;
      this.imageUploadPercent = file.percentage.toFixed(0);
    },
    // 上传图片
    handleAvatarSuccess(res) {
      this.imageFlag = false;
      this.imageUploadPercent = 0;
      this.imageUrl = res.datas.map.url
    }
  }
}
</script>

<style scoped>
.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 300px;
  display: inline-block;
}

.table {
  width: 100%;
  font-size: 14px;
}

.red {
  color: #ff0000;
}

.mr10 {
  margin-right: 10px;
}

.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
