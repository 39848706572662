<template>
  <div>
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <i class="el-icon-lx-cascades"></i> 基础表格
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="container">
      <el-table
          :data="tableData"
          border
          class="table"
          ref="multipleTable"
          header-cell-class-name="table-header"
          @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" align="center"></el-table-column>
        <el-table-column
            type="index"
            width="50"
            label="ID"
            align="center">
        </el-table-column>
        <el-table-column prop="contactMan" label="联系人"></el-table-column>
        <el-table-column prop="contactWay" label="联系方式"></el-table-column>
        <el-table-column prop="feedbackInfo" label="反馈信息"></el-table-column>
        <el-table-column prop="feedbackTime" label="反馈时间"></el-table-column>
        <el-table-column label="操作" width="180" align="center">
          <template #default="scope">
            <el-button
                type="text"
                icon="el-icon-view"
                @click="showInfo(scope.$index, scope.row)"
            >查看
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination background
                     @current-change="handleCurrentChange"
                     :current-page="pageNum"
                     :page-size="pageSize" layout="total, prev, pager, next, jumper"
                     :total="totalCount">
      </el-pagination>
    </div>

    <!-- 添加弹出框 -->
    <el-dialog title="详情" v-model="insertVisible" width="30%">
      <el-form ref="insertVisible" :model="feedback" label-width="70px">
        <el-form-item label="联系人">
          <el-input v-model="feedback.contactMan"></el-input>
        </el-form-item>
        <el-form-item label="联系方式">
          <el-input v-model="feedback.contactWay"></el-input>
        </el-form-item>
        <el-form-item label="反馈信息">
          <el-input type="textarea"
                    :autosize="{ minRows: 2, maxRows: 4}"
                    v-model="feedback.feedbackInfo"></el-input>
        </el-form-item>
      </el-form>
      <div class="demo-image__placeholder">
        <div class="block">
          <span class="demonstration"></span>
          <el-image :src="feedback.feedbackPic"></el-image>
        </div>
      </div>
      <template #footer>
                <span class="dialog-footer">
                    <el-button type="primary" @click="insertVisible = false">取 消</el-button>
                </span>
      </template>
    </el-dialog>


  </div>
</template>

<script>
export default {
  name: "FeedBack",
  inject: ['reload'],
  data() {
    return {

      tableData: [],

      feedback:{
        contactMan:'',
        contactWay:'',
        feedbackInfo:'',
        feedbackPic:''
      },

      srcList: [],

      insertVisible: false,

      // 默认显示第几页
      pageNum: 1,
      // 总条数，根据接口获取数据长度(注意：这里不能为空)
      totalCount: 1,
      // 默认每页显示的条数（可修改）
      pageSize: 10,

      idx: -1,
      id: -1
    }
  },
  created() {
    this.getData(this.pageSize, this.pageNum)
  },
  methods: {

    // 多选操作
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },

    // 获取数据
    getData(pageSize, pageNum) {
      this.$axios({
        method: "get",
        url: "/feedback/getList",
        params: {"pageSize": pageSize, "pageNum": pageNum}
      }).then(response => {
        console.log(response)
        this.tableData = response.data.datas.feedbackPageInfo.list
        this.tableData.map((item) => {
              this.srcList.push(item.feedbackPic)
            });
        this.totalCount = response.data.datas.feedbackPageInfo.total
      })
    },

    // 分页
    // 显示第几页
    handleCurrentChange(val) {
      // 切换页码时，要获取每页显示的条数
        this.getData(this.pageSize, val)
    },

    // 查看
    showInfo(index,row){
      this.insertVisible = true
      this.feedback = row
      console.log(row)
    }

  }
}
</script>

<style scoped>
.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 300px;
  display: inline-block;
}

.table {
  width: 100%;
  font-size: 14px;
}

.red {
  color: #ff0000;
}

.mr10 {
  margin-right: 10px;
}

.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
