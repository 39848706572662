<template>
  <div v-if="page === '1'">
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <i class="el-icon-lx-cascades"></i> 基础表格
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="container" v-if="page === '1'">
      <div class="handle-box">
        <el-button
            type="success"
            icon="el-icon-plus"
            class="handle-del mr10"
            @click="page = '2'"
            style="margin-right: 50px"
        >添 加
        </el-button>
        <el-input v-model="keyword" placeholder="标题或内容搜索" class="handle-input mr10"></el-input>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">搜索</el-button>
      </div>
      <el-table
          :data="tableData"
          border
          class="table"
          ref="multipleTable"
          header-cell-class-name="table-header"
          @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" align="center"></el-table-column>
        <el-table-column
            type="index"
            width="50"
            label="ID"
            align="center">
        </el-table-column>
        <el-table-column prop="newsId" label="资讯ID"></el-table-column>
        <el-table-column prop="newsTitle" label="资讯标题"></el-table-column>
        <el-table-column prop="bannerPic" label="资讯图片">
          <template v-slot="scope">
            <img :src="scope.row.newsPic" :preview-src-list="[scope.row.thumb]" min-width="70" height="70"/>
          </template>
        </el-table-column>
        <el-table-column prop="newsTime" label="资讯发布时间"></el-table-column>
        <el-table-column prop="newsCredits" label="资讯查看积分"></el-table-column>
        <el-table-column prop="newsShare" label="资讯分享积分"></el-table-column>
        <el-table-column label="操作" width="180" align="center">
          <template #default="scope">
            <el-button
                type="text"
                icon="el-icon-edit"
                @click="handleEdit(scope.$index, scope.row)"
            >编辑
            </el-button>
            <el-button
                type="text"
                icon="el-icon-delete"
                class="red"
                @click="handleDelete(scope.$index, scope.row)"
            >删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination background
                     @current-change="handleCurrentChange"
                     :current-page="pageNum"
                     :page-size="pageSize" layout="total, prev, pager, next, jumper"
                     :total="totalCount">
      </el-pagination>
    </div>
  </div>

  <div v-if="page === '2'">
    <el-form model="emailConfig" label-width="180px" style="margin-top: 50px">
      <el-form-item label="资讯标题">
        <el-input v-model="news.newsTitle"></el-input>
      </el-form-item>
      <!--      action修改成oss上传图片的路径        -->
      <el-form-item label="资讯图片">
        <el-upload
            class="avatar-uploader"
            action="https://yisheng.host/api/banner/upload"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :on-progress="uploadVideoProcess"
        >
          <img v-if="imageUrl" :src="imageUrl" class="avatar">
          <i v-else-if="imageFlag == false" class="el-icon-plus avatar-uploader-icon"></i>
          <el-progress v-if="imageFlag == true" type="circle" :percentage="imageUploadPercent"
                       style="margin-top:30px;"></el-progress>
        </el-upload>
        <p style="color: #DD4A68;margin-left: 10px">建议图片尺寸：100 x 100</p>
      </el-form-item>
      <el-form-item label="资讯查看积分">
        <el-input v-model="news.newsCredits"></el-input>
      </el-form-item>
      <el-form-item label="资讯分享积分">
        <el-input v-model="news.newsShare"></el-input>
      </el-form-item>
      <el-form-item label="资讯内容">
        <tinymce_editor v-model="news.newsDesc"
                        ref="editor1"
        >
        </tinymce_editor>
      </el-form-item>
      <el-row>
        <el-button type="primary" @click="saveInsert">确 定</el-button>
        <el-button type="danger" @click="back">返 回</el-button>
      </el-row>
    </el-form>
  </div>

  <div v-if="page === '3'">
    <el-form model="emailConfig" label-width="180px" style="margin-top: 50px">
      <el-form-item label="资讯标题">
        <el-input v-model="news.newsTitle"></el-input>
      </el-form-item>
      <!--      action修改成oss上传图片的路径        -->
      <el-form-item label="资讯图片">
        <el-upload
            class="avatar-uploader"
            action="https://yisheng.host/api/banner/upload"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :on-progress="uploadVideoProcess"
        >
          <img v-if="imageUrl" :src="imageUrl" class="avatar">
          <i v-else-if="imageFlag == false" class="el-icon-plus avatar-uploader-icon"></i>
          <el-progress v-if="imageFlag == true" type="circle" :percentage="imageUploadPercent"
                       style="margin-top:30px;"></el-progress>
        </el-upload>
        <p style="color: #DD4A68;margin-left: 10px">建议图片尺寸：100 x 100</p>
      </el-form-item>

      <el-form-item label="资讯查看积分">
        <el-input v-model="news.newsCredits"></el-input>
      </el-form-item>
      <el-form-item label="资讯分享积分">
        <el-input v-model="news.newsShare"></el-input>
      </el-form-item>
      <el-form-item label="资讯内容">
        <tinymce_editor ref="editor2"
        >
        </tinymce_editor>
      </el-form-item>
      <el-row>
        <el-button type="primary" @click="saveEdit">确 定</el-button>
        <el-button type="danger" @click="back">返 回</el-button>
      </el-row>
    </el-form>
  </div>



</template>

<script>
import TinyMce from '../../components/Tinymce/Tinymce.vue';

export default {
  name: "Msg",
  inject: ['reload'],
  components: {
    tinymce_editor: TinyMce,
  },
  data() {
    return {
      tableData: [],

      news: {
        newsId: '',
        newsTitle: '',
        newsDesc: '',
        newsCredits: '',
        newsPic: '',
        newsShare: ''
      },

      imageUrl:'',

      keyword:'',

      page: '1',

      pageNum: 1,
      // 总条数，根据接口获取数据长度(注意：这里不能为空)
      totalCount: 1,
      // 默认每页显示的条数（可修改）
      pageSize: 10,

      idx: -1,
      id: -1,

      imageFlag: false,
      imageUploadPercent: ''
    }
  },
  created() {
    this.getData(this.pageSize, this.pageNum)
  },
  methods: {

    // 多选操作
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },

    // 获取数据
    getData(pageSize, pageNum) {
      this.$axios({
        method: "get",
        url: "/news/getList",
        params: {"keyword": this.keyword, "pageSize": pageSize, "pageNum": pageNum}
      }).then(response => {
        if (response.data.code === 200) {
          this.tableData = response.data.datas.newsPageInfo.list
          this.totalCount = response.data.datas.newsPageInfo.total
        } else {
          this.$message.error("未搜索到相关数据");
        }
      })
    },

    // 搜索
    handleSearch() {
      this.getData(this.pageSize, this.pageNum)
    },

    // 添加
    saveInsert() {
      this.news.newsPic = this.imageUrl
      this.news.newsDesc = this.$refs.editor1.myValue
      this.$axios({
        method: "post",
        url: "/news/save",
        data: this.news
      }).then(response => {
        if (response.data.code == 200) {
          this.$message.success("添加成功");
          this.reload();
        } else {
          this.$message.error("添加失败");
        }
      })
    },

    // 编辑操作
    handleEdit(index, row) {
      this.idx = index;
      this.news = row;
      this.page = '3';
      this.imageUrl = row.newsPic;
      this.$nextTick(() => {
        this.$refs.editor2.myValue = row.newsDesc
      })
    },
    // 保存修改
    saveEdit() {
      this.news.newsPic = this.imageUrl
      this.news.newsDesc = this.$refs.editor2.myValue
      this.$axios({
        method: "put",
        url: "/news/edit",
        data: this.news
      }).then(response => {
        if (response.data.code == 200) {
          this.$message.success(`修改第 ${this.idx + 1} 行成功`);
          this.reload();
        } else {
          this.$message.error("修改失败");
        }
      })
    },

    // 删除操作
    handleDelete(index, row) {
      // 二次确认删除
      this.$confirm("确定要删除吗？", "提示", {
        type: "warning",
      }).then(res => {
        if (res == "confirm") {
          this.$axios({
            method: "delete",
            url: "/news/del",
            params: {"newsId": row.newsId}
          }).then(response => {
            if (response.data.code == 200) {
              this.$message.success("删除成功");
              this.reload()
              //this.tableData.splice(department, 1);
            } else {
              this.$message.error("删除失败");
            }
          })
        }
      })
    },

    // 分页
    // 显示第几页
    handleCurrentChange(val) {
      // 切换页码时，要获取每页显示的条数
      this.getData(this.pageSize, val)
    },

    back() {
      this.page = "1"
      this.reload()
    },

    uploadVideoProcess(event, file) {
      this.imageFlag = true;
      this.imageUploadPercent = file.percentage.toFixed(0);
    },
    // 上传图片
    handleAvatarSuccess(res) {
      this.imageFlag = false;
      this.imageUploadPercent = 0;
      this.imageUrl = res.datas.map.url
    }


  }
}
</script>

<style scoped>
.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 300px;
  display: inline-block;
}

.table {
  width: 100%;
  font-size: 14px;
}

.red {
  color: #ff0000;
}

.mr10 {
  margin-right: 10px;
}

.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
