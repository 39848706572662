<template>
  <div>
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <i class="el-icon-lx-cascades"></i> 基础表格
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="container">
      <div class="handle-box">
        <el-button
            type="success"
            icon="el-icon-plus"
            class="handle-del mr10"
            @click="insertVisible = true,wordStatus = 1"
            style="margin-right: 50px"
        >添 加
        </el-button>
        <el-input v-model="exam.examTitle" placeholder="真题标题" class="handle-input mr10"></el-input>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">搜索</el-button>
      </div>
      <el-table
          :data="tableData"
          border
          class="table"
          ref="multipleTable"
          header-cell-class-name="table-header"
          @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" align="center"></el-table-column>
        <el-table-column
            type="index"
            width="50"
            label="ID"
            align="center">
        </el-table-column>
        <el-table-column prop="examTitle" label="真题标题"></el-table-column>
        <el-table-column prop="examInfo" label="真题信息"></el-table-column>
        <el-table-column prop="examDesc" label="真题简介"></el-table-column>
        <el-table-column prop="examType" label="学科类型"></el-table-column>
        <el-table-column prop="examCredits" label="下载积分"></el-table-column>
        <el-table-column prop="examTime" label="添加时间"></el-table-column>
        <el-table-column prop="examPic" label="真题图片">
          <template v-slot="scope">
            <img :src="scope.row.examPic" :preview-src-list="[scope.row.thumb]" min-width="70" height="70"/>
          </template>
        </el-table-column>
        <el-table-column prop="examResource" label="真题资源">
          <template v-slot="scope">
            <span v-if="scope.row.examResource !== ''">
              <a :href="scope.row.examResource">
                <el-button type="primary">资源下载</el-button>
              </a>
            </span>
            <span v-if="scope.row.examResource === ''">
                <el-tag type="danger">暂无资源</el-tag>
            </span>
          </template>
        </el-table-column>
        <!--        <el-table-column prop="examResource" label="真题资源"></el-table-column>-->

        <el-table-column label="操作" width="180" align="center">
          <template #default="scope">
            <el-button
                type="text"
                icon="el-icon-edit"
                @click="handleEdit(scope.$index, scope.row)"
            >编辑
            </el-button>
            <el-button
                type="text"
                icon="el-icon-delete"
                class="red"
                @click="handleDelete(scope.$index, scope.row)"
            >删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination background
                     @current-change="handleCurrentChange"
                     :current-page="pageNum"
                     :page-size="pageSize" layout="total, prev, pager, next, jumper"
                     :total="totalCount">
      </el-pagination>
    </div>

    <!-- 添加弹出框 -->
    <el-dialog title="添加" v-model="insertVisible" width="30%" :before-close="close">
      <el-form ref="insertVisible" :model="exam" label-width="70px">
        <el-form-item label="真题标题">
          <el-input v-model="exam.examTitle"></el-input>
        </el-form-item>
        <el-form-item label="真题信息">
          <el-input
              type="textarea"
              :rows="2"
              placeholder="请输入内容"
              v-model="exam.examInfo">
          </el-input>
        </el-form-item>
        <el-form-item label="真题简介">
          <el-input
              type="textarea"
              :rows="2"
              placeholder="请输入内容"
              v-model="exam.examDesc">
          </el-input>
        </el-form-item>
        <el-form-item label="学科类型">
          <el-select v-model="exam.examType" placeholder="请选择学科类型">
            <el-option
                v-for="item in examType"
                :key="item.etId"
                :label="item.etName"
                :value="item.etName">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="下载积分">
          <el-input v-model="exam.examCredits"></el-input>
        </el-form-item>
        <!--      action修改成oss上传图片的路径        -->
        <el-form-item label="真题图片">
          <el-upload
              class="avatar-uploader"
              action="https://yisheng.host/api/exam/upload"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :on-progress="uploadImageProcess"
          >
            <img v-if="imageUrl" :src="imageUrl" class="avatar">
            <i v-else-if="imageFlag == false" class="el-icon-plus avatar-uploader-icon"></i>
            <el-progress v-if="imageFlag == true" type="circle" :percentage="imageUploadPercent"
                         style="margin-top:30px;"></el-progress>
          </el-upload>
          <p style="color: #DD4A68;margin-left: 10px">建议图片尺寸：173 x 150</p>
        </el-form-item>
        <el-form-item label="真题文件">
          <el-upload
              class="avatar-uploader"
              action="https://yisheng.host/api/exam/upload"
              :show-file-list="false"
              :on-success="handleWordSuccess"
              :on-progress="uploadWordProcess"
              v-if="wordStatus == 1"
              :before-upload="beforeUpload"
          >
            <img v-if="wordUrl" :src="wordUrl" class="avatar">
            <i v-else-if="wordFlag == false" class="el-icon-plus avatar-uploader-icon"></i>
            <el-progress v-if="wordFlag == true" type="circle" :percentage="wordUploadPercent"
                         style="margin-top:30px;"></el-progress>
          </el-upload>

          <el-upload
              class="avatar-uploader"
              action="https://yisheng.host/api/exam/upload"
              :show-file-list="false"
              v-if="wordStatus == 2"
          >
            <img src="../../assets/img/uploadSuccess.jpg" style="width: 40%;height: 75%;margin-top: 20px">
          </el-upload>
        </el-form-item>
      </el-form>
      <template #footer>
                <span class="dialog-footer">
                    <el-button @click="closeInsert">取 消</el-button>
                    <el-button type="primary" @click="saveInsert">确 定</el-button>
                </span>
      </template>
    </el-dialog>

    <!-- 添加弹出框 -->
    <el-dialog title="编辑" v-model="editVisible" width="30%" :before-close="close">
      <el-form ref="editVisible" :model="exam" label-width="70px">
        <el-form-item label="真题标题">
          <el-input v-model="exam.examTitle"></el-input>
        </el-form-item>
        <el-form-item label="真题信息">
          <el-input
              type="textarea"
              :rows="2"
              placeholder="请输入内容"
              v-model="exam.examInfo">
          </el-input>
        </el-form-item>
        <el-form-item label="真题简介">
          <el-input
              type="textarea"
              :rows="2"
              placeholder="请输入内容"
              v-model="exam.examDesc">
          </el-input>
        </el-form-item>
        <el-form-item label="学科类型">
          <el-select v-model="exam.examType" placeholder="请选择学科类型">
            <el-option
                v-for="item in examType"
                :key="item.etId"
                :label="item.etName"
                :value="item.etName">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="下载积分">
          <el-input v-model="exam.examCredits"></el-input>
        </el-form-item>
        <!--      action修改成oss上传图片的路径        -->
        <el-form-item label="真题图片">
          <el-upload
              class="avatar-uploader"
              action="https://yisheng.host/api/exam/upload"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :on-progress="uploadImageProcess"
          >
            <img v-if="imageUrl" :src="imageUrl" class="avatar">
            <i v-else-if="imageFlag == false" class="el-icon-plus avatar-uploader-icon"></i>
            <el-progress v-if="imageFlag == true" type="circle" :percentage="imageUploadPercent"
                         style="margin-top:30px;"></el-progress>
          </el-upload>
          <p style="color: #DD4A68;margin-left: 10px">建议图片尺寸：173 x 150</p>
        </el-form-item>
        <el-form-item label="真题文件">
          <el-upload
              class="avatar-uploader"
              action="https://yisheng.host/api/exam/upload"
              :show-file-list="false"
              :on-success="handleWordSuccess"
              :on-progress="uploadWordProcess"
              v-if="wordStatus == 1"
              :before-upload="beforeUpload"
          >
            <img v-if="wordUrl" :src="wordUrl" class="avatar">
            <i v-else-if="wordFlag == false" class="el-icon-plus avatar-uploader-icon"></i>
            <el-progress v-if="wordFlag == true" type="circle" :percentage="wordUploadPercent"
                         style="margin-top:30px;"></el-progress>
          </el-upload>

          <el-upload
              class="avatar-uploader"
              action="https://yisheng.host/api/exam/upload"
              :show-file-list="false"
              v-if="wordStatus == 2"
          >
            <img src="../../assets/img/uploadSuccess.jpg" style="width: 40%;height: 75%;margin-top: 20px">
          </el-upload>
        </el-form-item>
      </el-form>
      <template #footer>
                <span class="dialog-footer">
                    <el-button @click="closeEdit">取 消</el-button>
                    <el-button type="primary" @click="saveEdit">确 定</el-button>
                </span>
      </template>
    </el-dialog>

  </div>
</template>

<script>
export default {
  name: "Exam",
  inject: ['reload'],
  data() {
    return {
      tableData: [],

      exam: {
        examId: '',
        examInfo: '',
        examTitle: '',
        examType: '',
        examDesc: '',
        examPic: '',
        examResource: '',
        examCredits: '',
        examTime: ''
      },

      examType: [{
        etId: '',
        etName: '',
      }],

      insertVisible: false,
      editVisible: false,

      imageUrl: '',

      wordUrl: '',

      // 默认显示第几页
      pageNum: 1,
      // 总条数，根据接口获取数据长度(注意：这里不能为空)
      totalCount: 1,
      // 默认每页显示的条数（可修改）
      pageSize: 10,

      idx: -1,
      id: -1,

      imageFlag: false,
      imageUploadPercent: '',
      wordFlag: false,
      wordStatus: 1,
      wordUploadPercent: ''
    }
  },
  created() {
    this.getData(this.pageSize, this.pageNum)
    this.getExamType(-1, this.pageNum)
  },
  methods: {

    // 多选操作
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },

    // 获取数据
    getData(pageSize, pageNum) {
      this.$axios({
        method: "get",
        url: "/exam/getList",
        params: {"keyword": this.exam.examTitle, "pageSize": pageSize, "pageNum": pageNum}
      }).then(response => {
        console.log(response)
        this.tableData = response.data.datas.examPageInfo.list
        this.totalCount = response.data.datas.examPageInfo.total
      })
    },

    // 获取真题类型
    getExamType(pageSize, pageNum) {
      this.$axios({
        method: "get",
        url: "/examType/getList",
        params: {"pageSize": pageSize, "pageNum": pageNum}
      }).then(response => {
        console.log(response)
        this.examType = response.data.datas.examTypeList
      })
    },

    // 搜索
    handleSearch() {
      this.getData(this.pageSize, this.pageNum)
    },

    close(done) {
      this.exam = {
        examId: '',
        examInfo: '',
        examTitle: '',
        examType: '',
        examDesc: '',
        examPic: '',
        examResource: '',
        examCredits: '',
        examTime: ''
      };
      this.imageUrl = '',
      done();
    },

    closeInsert() {
      this.exam = {
        examId: '',
        examInfo: '',
        examTitle: '',
        examType: '',
        examDesc: '',
        examPic: '',
        examResource: '',
        examCredits: '',
        examTime: ''
      };
      this.insertVisible = false;
      this.imageUrl = '';
    },

    closeEdit() {
      this.exam = {
        examId: '',
        examInfo: '',
        examTitle: '',
        examType: '',
        examDesc: '',
        examPic: '',
        examResource: '',
        examCredits: '',
        examTime: ''
      };
      this.editVisible = false;
      this.imageUrl = '';
    },

    // 添加
    saveInsert() {
      this.exam.examPic = this.imageUrl
      if (this.wordUrl.length > 0) {
        this.exam.examResource = this.wordUrl
      }
      this.$axios({
        method: "post",
        url: "/exam/save",
        data: this.exam
      }).then(response => {
        console.log(response)
        if (response.data.code === 200) {
          this.$message.success("添加成功");
          this.reload();
        } else {
          this.$message.error("添加失败");
        }
      })
    },

    // 编辑操作
    handleEdit(index, row) {
      this.idx = index;
      this.exam = row;
      this.editVisible = true;
      this.imageUrl = row.examPic;
    },
    // 保存修改
    saveEdit() {
      this.exam.examPic = this.imageUrl
      if (this.wordUrl.length > 0) {
        this.exam.examResource = this.wordUrl
      }
      this.$axios({
        method: "put",
        url: "/exam/edit",
        data: this.exam
      }).then(response => {
        console.log(response)
        if (response.data.code === 200) {
          this.$message.success(`修改第 ${this.idx + 1} 行成功`);
          this.reload();
        } else {
          this.$message.error("修改失败");
        }
      })
    },

    // 删除操作
    handleDelete(index, row) {
      // 二次确认删除
      this.$confirm("确定要删除吗？", "提示", {
        type: "warning",
      }).then(res => {
        if (res === "confirm") {
          this.$axios({
            method: "delete",
            url: "/exam/del",
            params: {"examId": row.examId}
          }).then(response => {
            if (response.data.code === 200) {
              this.$message.success("删除成功");
              this.reload()
            } else {
              this.$message.error("删除失败");
            }
          })
        }
      })
    },

    // 分页
    // 显示第几页
    handleCurrentChange(val) {
      // 切换页码时，要获取每页显示的条数
      this.getData(this.pageSize, val)
    },

    uploadImageProcess(event, file) {
      this.imageFlag = true;
      this.imageUploadPercent = file.percentage.toFixed(0);
    },
    // 上传图片
    handleAvatarSuccess(res) {
      this.imageFlag = false;
      this.imageUploadPercent = 0;
      this.imageUrl = res.datas.map.url
    },
    uploadWordProcess(event, file) {
      this.wordFlag = true;
      if (file.percentage.toFixed(0) < 97) {
        this.wordUploadPercent = file.percentage.toFixed(0);
      } else {
        this.wordUploadPercent = 97
      }
    },
    handleWordSuccess(res) {
      this.wordStatus = 2;
      this.wordFlag = false;
      this.wordUploadPercent = 0;
      this.wordUrl = res.datas.map.url
    },
    beforeUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 30
      if (!isLt2M) {
        this.$message({
          message: '上传文件大小不能超过 30MB!',
          type: 'error'
        });
      }
      return isLt2M;
    },
  }
}
</script>

<style scoped>
.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 300px;
  display: inline-block;
}

.table {
  width: 100%;
  font-size: 14px;
}

.red {
  color: #ff0000;
}

.mr10 {
  margin-right: 10px;
}

.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
