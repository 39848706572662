<template>
  <div>
  <div v-if="page === '1'">
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <i class="el-icon-lx-cascades"></i> 基础表格
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="container" v-if="page === '1'">
      <div class="handle-box">
        <el-button
            type="success"
            icon="el-icon-plus"
            class="handle-del mr10"
            @click="page = '2'"
            style="margin-right: 50px"
        >添 加
        </el-button>
        <el-input v-model="msg.msgTitle" placeholder="消息标题" class="handle-input mr10"></el-input>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">搜索</el-button>
      </div>
      <el-table
          :data="tableData"
          border
          class="table"
          ref="multipleTable"
          header-cell-class-name="table-header"
          @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" align="center"></el-table-column>
        <el-table-column
            type="index"
            width="50"
            label="ID"
            align="center">
        </el-table-column>
        <el-table-column prop="msgTitle" label="消息标题"></el-table-column>
        <el-table-column prop="msgInfo" label="消息信息"></el-table-column>
        <el-table-column prop="msgType" label="消息类型"></el-table-column>
        <el-table-column prop="msgTime" label="添加时间"></el-table-column>
        <el-table-column prop="msgPushTime" label="发送时间"></el-table-column>
        <el-table-column prop="msgStatus" label="是否发送">
          <template v-slot="scope">
            <p v-if="scope.row.msgStatus==='1'">
              <el-tag type="success">已发送</el-tag>
            </p>
            <p v-if="scope.row.msgStatus==='0'">
              <el-button
                  type="danger"
                  round
                  @click="handlePush(scope.$index, scope.row)"
              >点击发送
              </el-button>
            </p>
            <p v-if="scope.row.msgStatus==='2'">
              <el-tag type="primary">定时发送</el-tag>
            </p>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="180" align="center">
          <template #default="scope">
            <el-button
                type="text"
                icon="el-icon-edit"
                @click="handleEdit(scope.$index, scope.row)"
            >编辑
            </el-button>
            <el-button
                type="text"
                icon="el-icon-delete"
                class="red"
                @click="handleDelete(scope.$index, scope.row)"
            >删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination background
                     @current-change="handleCurrentChange"
                     :current-page="pageNum"
                     :page-size="pageSize" layout="total, prev, pager, next, jumper"
                     :total="totalCount">
      </el-pagination>
    </div>
  </div>

  <div v-if="page === '2'">
    <el-form model="emailConfig" label-width="180px" style="margin-top: 50px">
      <el-form-item label="消息标题">
        <el-input v-model="msg.msgTitle"></el-input>
      </el-form-item>
      <el-form-item label="消息信息">
        <el-input v-model="msg.msgInfo"></el-input>
      </el-form-item>
      <el-form-item label="消息类型">
        <el-select v-model="msg.msgType" placeholder="请选择消息类型">
          <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>

        <div class="block" style="margin-left: 65px;margin-bottom: 30px" v-if="msg.msgType === '活动定时消息'">
          <span class="demonstration">定时推送时间</span>
          <el-date-picker
              v-model="msg.msgPushTime"
              type="datetime"
              style="margin-left: 20px"
              placeholder="选择开始时间">
          </el-date-picker>
        </div>

      <el-form-item label="消息内容">
        <tinymce_editor ref="editor1"
        >
        </tinymce_editor>
      </el-form-item>
      <el-row>
        <el-button type="primary" @click="saveInsert">确 定</el-button>
        <el-button type="danger" @click="back">返 回</el-button>
      </el-row>
    </el-form>
  </div>

  <div v-if="page === '3'">
    <el-form model="emailConfig" label-width="180px" style="margin-top: 50px">
      <el-form-item label="消息标题">
        <el-input v-model="msg.msgTitle"></el-input>
      </el-form-item>
      <el-form-item label="消息信息">
        <el-input v-model="msg.msgInfo"></el-input>
      </el-form-item>
      <el-form-item label="消息类型">
        <el-select v-model="msg.msgType" placeholder="请选择消息类型">
          <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>

      <div class="block" style="margin-left: 65px;margin-bottom: 30px" v-if="msg.msgType === '活动定时消息'">
        <span class="demonstration">定时推送时间</span>
        <el-date-picker
            v-model="msg.msgPushTime"
            type="datetime"
            style="margin-left: 20px"
            placeholder="选择开始时间">
        </el-date-picker>
      </div>

      <el-form-item label="消息内容">
        <tinymce_editor ref="editor2"
        >
        </tinymce_editor>
      </el-form-item>
      <el-row>
        <el-button type="primary" @click="saveEdit">确 定</el-button>
        <el-button type="danger" @click="back">返 回</el-button>
      </el-row>
    </el-form>
  </div>


  </div>
</template>

<script>
import TinyMce from '../../components/Tinymce/Tinymce.vue';

export default {
  name: "Msg",
  inject: ['reload'],
  components: {
    tinymce_editor: TinyMce,
  },
  data() {
    return {
      tableData: [],

      orgData: [],

      show: false,
      msg: {
        msgId: '',
        msgInfo: '',
        msgTitle: '',
        msgDesc: '',
        msgTime: '',
        msgStatus: '',
        msgType: '',
        msgPushTime: ''
      },

      options: [{
        value: '系统消息',
        label: '系统消息'
      }, {
        value: '活动定时消息',
        label: '活动定时消息'
      }],

      page: '1',

      insertVisible: false,
      editVisible: false,

      imageUrl: '',

      // 默认显示第几页
      pageNum: 1,
      // 总条数，根据接口获取数据长度(注意：这里不能为空)
      totalCount: 1,
      // 默认每页显示的条数（可修改）
      pageSize: 10,

      idx: -1,
      id: -1
    }
  },
  created() {
    this.getData(this.pageSize, this.pageNum)
  },
  methods: {

    // 多选操作
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },

    // 获取数据
    getData(pageSize, pageNum) {
      this.$axios({
        method: "get",
        url: "/message/getList",
        params: {"msgTitle": this.msg.msgTitle, "pageSize": pageSize, "pageNum": pageNum}
      }).then(response => {
        console.log(response)
        if (response.data.code === 200) {
          this.tableData = response.data.datas.messagePageInfo.list
          this.totalCount = response.data.datas.messagePageInfo.total
        } else {
          this.$message.error("未搜索到相关数据");
        }
      })
    },

    // 搜索
    handleSearch() {
      this.getData(this.pageSize, this.pageNum)
    },

    // 点击发送
    handlePush(index, row) {
      this.msg.msgStatus = 1
      this.msg.msgId = row.msgId
      this.$axios({
        method: "put",
        url: "/message/edit",
        data: this.msg
      }).then(response => {
        console.log(response)
        if (response.data.code == 200) {
          this.$message.success(`发送成功`);
          this.reload();
        } else {
          this.$message.error("发送失败");
        }
      })
    },


    // 添加
    saveInsert() {
      this.msg.msgDesc = this.$refs.editor1.myValue
      if (this.msg.msgPushTime !== '') {
        let month = this.msg.msgPushTime.getMonth() + 1
        if(month < 10){
          month = '0' + month
        }
        let date = this.msg.msgPushTime.getDate()
        if(date < 10){
          date = '0' + date
        }
        let hours = this.msg.msgPushTime.getHours()
        if(hours < 10){
          hours = '0' + hours
        }
        let minutes = this.msg.msgPushTime.getMinutes()
        if(minutes < 10){
          minutes = '0' + minutes
        }
        this.msg.msgPushTime = this.msg.msgPushTime.getFullYear() + '-' + month + '-' + date + ' ' + hours + ':' + minutes
      }
      this.$axios({
        method: "post",
        url: "/message/save",
        data: this.msg
      }).then(response => {
        if (response.data.code == 200) {
          this.$message.success("添加成功");
          this.reload();
        } else {
          this.$message.error("添加失败");
        }
      })
    },

    // 编辑操作
    handleEdit(index, row) {
      this.idx = index;
      this.msg = row;
      this.page = '3';
      this.$nextTick(() => {
        this.$refs.editor2.myValue = row.msgDesc
      })
    },
    // 保存修改
    saveEdit() {
      this.msg.msgDesc = this.$refs.editor2.myValue
      this.$axios({
        method: "put",
        url: "/message/edit",
        data: this.msg
      }).then(response => {
        console.log(response)
        if (response.data.code == 200) {
          this.$message.success(`修改第 ${this.idx + 1} 行成功`);
          this.reload();
        } else {
          this.$message.error("修改失败");
        }
      })
    },

    // 删除操作
    handleDelete(index, row) {
      // 二次确认删除
      this.$confirm("确定要删除吗？", "提示", {
        type: "warning",
      }).then(res => {
        if (res == "confirm") {
          this.$axios({
            method: "delete",
            url: "/message/del",
            params: {"msgId": row.msgId}
          }).then(response => {
            if (response.data.code == 200) {
              this.$message.success("删除成功");
              this.reload()
              //this.tableData.splice(department, 1);
            } else {
              this.$message.error("删除失败");
            }
          })
        }
      })
    },

    // 分页
    // 显示第几页
    handleCurrentChange(val) {
      // 切换页码时，要获取每页显示的条数
      this.getData(this.pageSize, val)
    },

    back() {
      this.page = "1"
      this.reload()
    },

    // 上传图片
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }
      return isLt2M;
    },

    // 消息富文本


  }
}
</script>

<style scoped>
.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 300px;
  display: inline-block;
}

.table {
  width: 100%;
  font-size: 14px;
}

.red {
  color: #ff0000;
}

.mr10 {
  margin-right: 10px;
}

.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
